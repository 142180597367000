import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SmartLink from "../Navigation/SmartLink";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 5,
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    link: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        color: "#00863F",
        textDecoration: "none",
        transition: "0.25s ease-in-out",
        padding: "0 1rem",
        "&:hover": {
            color: "#11111150",
        },
    },
}));

const openSettings = e => {
    e.preventDefault();
    cc.showSettings();
}

function SecondaryNav(props) {
    const classes = useStyles();

    useEffect(() => onloadByscuit(), [])

    return (
        <div className={clsx(classes.root, props.className)}>
            {props.data.map((x) => (
                <SmartLink key={x.to} to={x.to} className={classes.link} text={x.text} />
            ))}
            <a href="#"
                data-cc="c-settings"
                aria-haspopup="dialog"
                className={classes.link}
                onClick={openSettings}
                >
                Cookie Settings
            </a>
        </div>
    );
}

SecondaryNav.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
};

export default SecondaryNav;
