import { useEffect } from "react";

const useBodyScript = (content) => {
    useEffect(() => {
        const script = document.createElement(`script`);
        script.textContent = content;
        if (content)
            document.body.insertBefore(script, document.body.firstChild);
        return () => {
            if (content) document.body.removeChild(script);
        };
    }, [content]);
};

export default useBodyScript;
