import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Typography, Grid } from "@material-ui/core";

import SimpleLocation from "./SimpleLocation";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2rem 4rem",
    },
    title: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 24,
        fontWeight: 400,
        lineHeight: "26px",
    },
}));

function SimpleLocationPanel(props) {
    const classes = useStyles();
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={clsx(classes.root, props.className)}
        >
            <Typography className={classes.title}>
                {props.province.replace(/_/g, ` `)}
            </Typography>
            {props.cities.map((x) => (
                <SimpleLocation
                    key={x.address}
                    city={x.city}
                    address={x.address}
                />
            ))}
        </Grid>
    );
}

SimpleLocationPanel.propTypes = {
    className: PropTypes.string,
    province: PropTypes.string.isRequired,
    cities: PropTypes.array.isRequired,
};

export default SimpleLocationPanel;
