import { useEffect } from "react";

const useStructuredData = (content) => {
    useEffect(() => {
        const script = document.createElement(`script`);
        script.textContent = content;
        script.type = "application/ld+json";
        if (content) document.head.appendChild(script);
        return () => {
            if (content) document.head.removeChild(script);
        };
    }, [content]);
};

export default useStructuredData;
