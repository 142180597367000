import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    makeStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Collapse,
    FormHelperText,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close as CloseIcon } from "@material-ui/icons";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

import RestService from "../../../services/RestService";
import { useCityState } from "../../../context/CityContext";

import { Button } from "../../../tags";

import "./index.css";

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "& h2": {
            fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
            fontSize: 40,
            lineHeight: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    },
    subtitle: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 24,
        lineHeight: "34px",
        marginBottom: "0 !important",
        marginTop: "2rem !important",
    },
    close: {
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        height: 32,
        width: 32,
        padding: 2,
        borderRadius: 0,
        transition: "0.25s ease-in-out",
        "&:hover": {
            color: "#fff",
        },
        marginLeft: "0.5rem",
    },
    content: {
        margin: "1rem 0",
    },
    footer: {
        backgroundColor: "#111",
        color: "#fff",
        padding: "1rem 1.5rem",
    },
    button: {
        width: "100%",
        margin: 0,
    },
    location: {
        color: theme.palette.primary.main,
    },
    input: {
        width: "100%",
        margin: "0.5rem 0",
    },
    inputSecondary: {
        width: "100%",
        margin: "0.5rem 0",
    },
    backdrop: {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.2);",
        color: theme.palette.primary.light,
        zIndex: 9999,
    },
    validationMsg: {
        color: theme.palette.error.main,
        textAlign: "center",
        margin: "0.75rem 0 0 0",
        fontWeight: "bold",
    },
    btmCntnt: {
        margin: "2rem 0",
    },
    header: {
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
    },
    icon: {
        fontSize: "2rem",
    },
    alert: {
        position: "fixed",
        top: 15,
        left: 25,
        zIndex: 9999,
    },
    helperText: {
        color: theme.palette.error.main,
        fontSize: 12,
    },
}));

const minDate = new Date(new Date().getTime() + 172800000);

function Modal(props) {
    const classes = useStyles();
    const city = props.city || useCityState();
    const [operatingHours, setOperatingHours] = useState([]);

    const [name, setName] = useState(``);
    const [phone, setPhone] = useState(``);
    const [email, setEmail] = useState(``);
    const [serviceType, setServiceType] = useState(``);
    const [problemDescription, setProblemDescription] = useState(``);
    const [make, setMake] = useState(``);
    const [model, setModel] = useState(``);
    const [year, setYear] = useState(``);
    const addDays = 2;
    const [preferredDate, setPreferredDate] = useState(null);
    const [preferredTimeOfDay, setPreferredTimeOfDay] = useState(`AM`);

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [severity, setSeverity] = useState(`success`);
    const [msg, setMsg] = useState(``);
    const [showValidationMsg, setShowValidationMsg] = useState(false);

    const [nameErr, setNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [serviceTypeErr, setServiceTypeErr] = useState(false);
    const [problemDescriptionErr, setProblemDescriptionErr] = useState(false);
    const [makeErr, setMakeErr] = useState(false);
    const [modelErr, setModelErr] = useState(false);
    const [yearErr, setYearErr] = useState(false);
    const [preferredDateErr, setPreferredDateErr] = useState(false);

    const formSubmittedSuccessfully = () => {
        const data = {
            event:
                props.type && props.type === `appointment`
                    ? `appointment_requested`
                    : `quote_requested`,
            category:
                props.type && props.type === `appointment`
                    ? `Appointment`
                    : `Quote`,
            label:
                props.type && props.type === `appointment`
                    ? `Appointment Form Submission`
                    : `Quote Form Submission`,
        };
        if (typeof dataLayer !== `undefined` && dataLayer !== null) {
            dataLayer.push(data);
        } else {
            console.log(
                `dataLayer object is not available. Check that the appropriate head and body tags exist`
            );
        }
    };

    const preferredDateIsClosed = () => {
        let dayIsClosed = false;

        if (props.type && props.type === "appointment") {
            operatingHours.forEach((day) => {
                if (
                    preferredDate.getDay() ===
                    moment().day(day.day_of_the_week).day()
                ) {
                    dayIsClosed = day.status === "Closed";
                }
            });
        }

        return dayIsClosed;
    };

    const onSubmitClick = async () => {
        // validate fields
        if (name === ``) setNameErr(true);
        else setNameErr(false);

        if (phone === ``) setPhoneErr(true);
        else setPhoneErr(false);

        if (email === ``) setEmailErr(true);
        else setEmailErr(false);

        if (serviceType === ``) setServiceTypeErr(true);
        else setServiceTypeErr(false);

        if (problemDescription === ``) setProblemDescriptionErr(true);
        else setProblemDescriptionErr(false);

        if (make === ``) setMakeErr(true);
        else setMakeErr(false);

        if (model === ``) setModelErr(true);
        else setModelErr(false);

        if (year === ``) setYearErr(true);
        else setYearErr(false);

        if (
            (!preferredDate && props.type === "appointment") ||
            (preferredDateIsClosed() && props.type === "appointment") ||
            (preferredDate < minDate && props.type === "appointment")
        ) {
            setPreferredDateErr(true);
        } else {
            setPreferredDateErr(false);
        }

        if (
            name === `` ||
            phone === `` ||
            email === `` ||
            serviceType === `` ||
            problemDescription === `` ||
            make == `` ||
            model === `` ||
            year === `` ||
            (preferredDate === null && props.type === "appointment") ||
            (preferredDate < moment(minDate).startOf("day").toDate() &&
                props.type === "appointment") ||
            (preferredDateIsClosed() && props.type === "appointment")
        ) {
            setShowValidationMsg(true);
            return;
        } else setShowValidationMsg(false);

        // close form and show loader
        props.onClose();
        setLoading(true);

        // send request to server
        const obj = {
            type: props.type,
            city,
            name,
            phone,
            email,
            serviceType,
            problemDescription,
            make,
            model,
            year,
            preferredDate,
            preferredTimeOfDay,
        };
        try {
            const res = await RestService.post(`/form`, obj);
            if (res.status !== 200 || res.data === false) throw Error();
            // trigger Google Analytics tag manager event
            formSubmittedSuccessfully();
            // give user feedback
            setMsg(`Request sent successfully.`);
            setSeverity(`success`);
            setShow(true);
            // reset input fields
            setName(``);
            setPhone(``);
            setEmail(``);
            setServiceType(``);
            setProblemDescription(``);
            setMake(``);
            setModel(``);
            setYear(``);
        } catch (err) {
            console.log(err);
            setMsg(
                `An error occured while submitting the form. Please try again later.`
            );
            setSeverity(`error`);
            setShow(true);
        }

        // hide loader
        setLoading(false);
    };

    const disableClosedDates = (date) => {
        let dayIsClosed = false;
        operatingHours.forEach((day) => {
            if (date.getDay() === moment().day(day.day_of_the_week).day()) {
                dayIsClosed = day.status === "Closed";
            }
        });

        return dayIsClosed;
    };

    const [services, setServices] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(``);
    useEffect(() => {
        RestService.get(`/service-types`).then((res) => {
            if (res.status === 200) {
                setServices(res.data.map((x) => x.type));
            }
        });
        RestService.get(`/locations?city=${city}`).then((res) => {
            if (res.data.length > 0 && res.data[0].phone_number) {
                setPhoneNumber(res.data[0].phone_number);
            }
            if (res.data.length > 0 && res.data[0].operating_hours) {
                setOperatingHours(res.data[0].operating_hours);
            }
        });
    }, [setServices, setPhoneNumber, setOperatingHours, city]);

    return (
        <>
            {loading && (
                <div className={classes.backdrop}>
                    <CircularProgress color="inherit" />
                </div>
            )}
            <Collapse in={show} className={classes.alert}>
                <Alert
                    severity={severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setShow(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                >
                    {msg}
                </Alert>
            </Collapse>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                className={classes.root}
                scroll="body"
                aria-labelledby="DialogTitle"
                aria-describedby="DialogDescription"
            >
                <div className={classes.header}>
                    <DialogTitle id="DialogTitle" className={classes.title}>
                        <span>
                            {props.type && props.type === `appointment`
                                ? `Book an Appointment`
                                : `Request a Quote`}
                        </span>
                        <IconButton
                            className={classes.close}
                            onClick={props.onClose}
                        >
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    </DialogTitle>
                </div>
                <DialogContent dividers>
                    <Typography variant="body1" className={classes.location}>
                        Location: {city}
                    </Typography>
                    <Typography
                        variant="body1"
                        id="DialogContent"
                        className={classes.content}
                    >
                        To request immediate assistance, please call us at{" "}
                        <b>{phoneNumber}</b>. To book an online appointment,
                        please fill out the following form:
                    </Typography>
                    <Typography
                        className={clsx(classes.content, classes.subtitle)}
                    >
                        Your Information
                    </Typography>
                    {/* Information Inputs */}
                    <TextField
                        variant="outlined"
                        label="Name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        className={classes.input}
                        error={nameErr}
                        helperText={nameErr ? `Please enter your name.` : ``}
                    />
                    <TextField
                        variant="outlined"
                        label="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        className={classes.input}
                        error={emailErr}
                        helperText={emailErr ? `Please enter your email.` : ``}
                    />
                    <TextField
                        variant="outlined"
                        label="Phone"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        className={classes.input}
                        error={phoneErr}
                        helperText={
                            phoneErr ? `Please enter your phone number.` : ``
                        }
                    />
                    {/* End Information Inputs */}
                    <Typography
                        className={clsx(classes.content, classes.subtitle)}
                    >
                        Service Information
                    </Typography>
                    {/* Service Information Inputs */}
                    <FormControl className={classes.inputSecondary}>
                        <InputLabel id="ServiceTypeSelectLabel">
                            Type of Service
                        </InputLabel>
                        <Select
                            labelId="ServiceTypeSelectLabel"
                            id="ServiceTypeSelect"
                            value={serviceType}
                            onChange={(e) => setServiceType(e.target.value)}
                        >
                            {services.map((x) => (
                                <MenuItem key={x} value={x}>
                                    {x}
                                </MenuItem>
                            ))}
                        </Select>
                        {serviceTypeErr && (
                            <FormHelperText className={classes.helperText}>
                                Please select a service type.
                            </FormHelperText>
                        )}
                    </FormControl>
                    <TextField
                        variant="outlined"
                        label="Problem Description"
                        value={problemDescription}
                        onChange={(event) =>
                            setProblemDescription(event.target.value)
                        }
                        className={classes.input}
                        error={problemDescriptionErr}
                        helperText={
                            problemDescriptionErr
                                ? `Please describe your issue.`
                                : ``
                        }
                    />
                    <TextField
                        variant="outlined"
                        label="Make"
                        value={make}
                        onChange={(event) => setMake(event.target.value)}
                        className={classes.input}
                        error={makeErr}
                        helperText={
                            makeErr
                                ? `Enter the manufacturer of your vehicle.`
                                : ``
                        }
                    />
                    <TextField
                        variant="outlined"
                        label="Model"
                        value={model}
                        onChange={(event) => setModel(event.target.value)}
                        className={classes.input}
                        error={modelErr}
                        helperText={
                            modelErr ? `Enter the model of your vehicle.` : ``
                        }
                    />
                    <TextField
                        type="number"
                        variant="outlined"
                        label="Year"
                        value={year}
                        onChange={(event) => setYear(event.target.value)}
                        className={classes.input}
                        error={yearErr}
                        helperText={
                            yearErr
                                ? `Enter the year your vehicle was made.`
                                : ``
                        }
                    />
                    {/* Clients are not ready to book appointment date/time for a quote */}
                    {props.type && props.type === `appointment` ? (
                        <>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="date-picker-dialog"
                                    margin="normal"
                                    label="Preferred Date"
                                    format="yyyy/MM/dd"
                                    value={preferredDate}
                                    minDate={minDate}
                                    onChange={(date) => setPreferredDate(date)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change preferred date",
                                    }}
                                    className={classes.inputSecondary}
                                    inputProps={{ readOnly: true }}
                                    shouldDisableDate={disableClosedDates}
                                    error={preferredDateErr}
                                    helperText={
                                        preferredDateErr
                                            ? `Select an Available Date.`
                                            : ``
                                    }
                                />
                            </MuiPickersUtilsProvider>
                            <FormControl className={classes.inputSecondary}>
                                <InputLabel id="PreferredTimeOfDayLabel">
                                    Preferred Time of Day
                                </InputLabel>
                                <Select
                                    labelId="PreferredTimeOfDayLabel"
                                    id="PreferredTimeOfDay"
                                    value={preferredTimeOfDay}
                                    onChange={(e) =>
                                        setPreferredTimeOfDay(e.target.value)
                                    }
                                >
                                    <MenuItem value="AM">AM</MenuItem>
                                    <MenuItem value="PM">PM</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    ) : null}
                    {/* End Service Information Inputs */}
                    <Typography
                        variant="body1"
                        className={clsx(classes.content, classes.btmCntnt)}
                    >
                        *Providing your phone number allows us, if necessary, to
                        call you with a few questions to complete your request
                        and find the right part for your vehicle. Your
                        information will not be shared with any third parties,
                        plus we will not send you promotions without your
                        consent.
                    </Typography>
                    <Button
                        className={classes.button}
                        onClick={onSubmitClick}
                        disabled={loading}
                    >
                        Submit{" "}
                        {props.type.charAt(0).toLocaleUpperCase() +
                            props.type.slice(1)}{" "}
                        Request
                    </Button>
                    <Collapse in={showValidationMsg}>
                        <Typography
                            variant="body1"
                            className={classes.validationMsg}
                        >
                            Please fill out all fields in the form above.
                        </Typography>
                    </Collapse>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Typography variant="body1">
                        We will contact you as soon as possible to confirm your
                        exact appointment time and details. To request immediate
                        assistance, please call us at <b>{phoneNumber}</b>.
                    </Typography>
                </DialogActions>
            </Dialog>
        </>
    );
}

Modal.propTypes = {
    type: PropTypes.string, // appointment, quote
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    city: PropTypes.string,
};

export default Modal;
