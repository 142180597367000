import React, { useState } from "react";
import clsx from "clsx";
import {
    makeStyles,
    Button,
    Grid,
    Typography,
    AppBar,
    Tabs,
    Tab,
    Box,
    Hidden,
} from "@material-ui/core";
import { Email as EmailIcon } from "@material-ui/icons";

import Meta from "../Page/Meta";
import MainLayout from "../../layouts/MainLayout";
import Header from "../../components/generic/Header";
import GoogleMap from "../Locations/GoogleMap";
import Modal from "../../components/main/Modal";
import ButtonComponent from "../../components/generic/Button";
import RichText from "../../components/main/RichText";
import Question from "../../components/generic/Question";

import RestService from "../../services/RestService";
import DataService from "../../services/DataService";

import DefaultImage from "../../assets/images/default-location-image.jpg";

import PhoneIcon from "../../assets/icons/phone-black.svg";
import InfoIcon from "../../assets/icons/info-black.svg";
import CalendarIcon from "../../assets/icons/calendar.svg";
import CalculatorIcon from "../../assets/icons/calculator.svg";
import ShareIcon from "../../assets/icons/share.svg";

import "./index.css";

import useStructuredData from "../../hooks/useStructuredData";
import useHeadScript from "../../hooks/useHeadScript";
import useBodyScript from "../../hooks/useBodyScript";
import useHeadNoScript from "../../hooks/useHeadNoScript";
import useBodyNoScript from "../../hooks/useBodyNoScript";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    root: {},
    links: {
        display: "flex",
        flexDirection: "column",
        padding: "0 0 3rem 0",
        marginTop: "-1rem",
    },
    link: {
        color: "#fff !important",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: "1.5rem",
        justifyContent: "flex-start",
    },
    container: {
        padding: "2rem 8rem",
        [theme.breakpoints.down("sm")]: {
            padding: "2rem 4rem",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "2rem 2rem",
        },
    },
    title: {
        marginBottom: "1rem",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 28,
        lineHeight: "38px",
    },
    rightContainer: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "4rem !important",
        [theme.breakpoints.down("sm")]: {
            padding: "2rem 2rem !important",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0 2rem !important",
        },
    },
    btn: {
        backgroundColor: theme.palette.cta.main,
        borderRadius: 10,
        padding: "0.6rem 0",
        color: "#fff !important",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        marginTop: "1rem",
        width: "100%",

        "&:hover": {
            color: theme.palette.cta.main + " !important",
        },
    },
    btnShare: {
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.primary.main,
        },
    },
    btnFB: {
        backgroundColor: "#3b5998",
        "&:hover": {
            color: theme.palette.primary.main,
        },
    },
    facebookIcon: {
        height: "25px",
        width: "25px",
    },
    hours: {},
    hour: {
        margin: 0,
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 24,
        lineHeight: "48px",
        [theme.breakpoints.down("xs")]: {
            fontSize: 20,
        },
    },
    letter: {
        color: theme.palette.primary.main,
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        marginRight: "1.25rem",
        fontSize: 24,
        lineHeight: "48px",
    },
    tabContainer: {
        backgroundColor: "transparent",
        boxShadow: "none",
        "& div": {
            justifyContent: "space-between !important",
        },
    },
    tabs: {
        backgroundColor: "#eee",
        borderRadius: "0 0 20px 20px",
    },
    tab: {
        backgroundColor: "#111",
        borderRadius: "20px 20px 0 0",
        opacity: 1,
        maxWidth: "none",
        width: "31%",
        textTransform: "unset",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 28,
        lineHeight: "38px",
    },
    tabText: {
        textTransform: "unset",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 28,
        lineHeight: "38px",
    },
    hoursTitle: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 28,
        lineHeight: "38px",
        [theme.breakpoints.down("md")]: {
            marginTop: "1rem",
        },
    },
    letterWidth: {
        maxWidth: 50,
    },
    status: {
        textTransform: "uppercase",
    },
    infoContainer: {
        [theme.breakpoints.down("md")]: {
            padding: 0,
        },
    },
    richText: {
        fontFamily: `"Palanquin", "Helvetica", sans-serif`,
        fontSize: 16,
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            lineHeight: "20px",
        },
    },
    emailIconContainer: {
        backgroundColor: "#111",
        width: 32,
        height: 32,
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    emailLink: {
        textTransform: "unset",
    },
    phoneLink: {
        textDecoration: "unset",
        display: "flex",
        alignItems: "center",
        "&::before": {
            content: `url(${PhoneIcon})`,
            width: 32,
            height: 32,
            marginLeft: 4,
            marginRight: 8,
        },
    },
    halfwidth: {
        maxWidth: 580,
    },
    short: {
        fontSize: 10,
        lineHeight: 4,
    },
    customLink: {
        fontSize: "1rem",
        fontFamily: `"Palanquin", "Helvetica", sans-serif`,
        fontWeight: 400,
        lineHeight: "24px",
        maxWidth: 580,
        marginBottom: 10,
        "& a": {
            color: "#00863F",
            textDecoration: "unset",
            "&:hover": {
                color: "#11111150",
            },
        }
    },
}));

const formatTime = (timeStr) => {
    let time = `PM`;
    let hour = parseInt(`${timeStr[0]}${timeStr[1]}`);
    if (hour > 12) hour -= 12;
    else if (hour !== 12) time = `AM`;
    return `${hour}:${timeStr[3]}${timeStr[4]} ${time}`;
};

function TabPanel(props) {
    const { children, value, index, id, labelledById, ...other } = props;
    const classes = useStyles();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={id}
            aria-labelledby={labelledById}
            {...other}
            className={classes.tabs}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function FbLinkButton(props) {
    const classes = useStyles();
    const { fbLink } = props;
    if (fbLink) {
        return (
            <Button
                className={clsx(classes.btn, classes.btnFB)}
                component={`a`}
                href={fbLink}
                target="_blank"
                rel="noopener"
            >
                <FontAwesomeIcon
                    icon={faFacebookSquare}
                    className={clsx(classes.facebookIcon)}
                    size="2x"
                />
                &nbsp;Visit Our Facebook Page
            </Button>
        );
    } else {
        return "";
    }
}

function Location(props) {
    useStructuredData(props.structuredData);
    useHeadNoScript(props.headNoScriptContent);
    useBodyNoScript(props.bodyNoScriptContent);
    useHeadScript(props.headScriptContent);
    useBodyScript(props.bodyScriptContent);
    const imgUrl = props.aagLocation.image
        ? props.aagLocation.image.url
        : DefaultImage;
    const reactImage = imgUrl === DefaultImage;
    const classes = useStyles();
    const [modalType, setModalType] = useState(`appointment`);
    const [modalOpen, setModalOpen] = useState(false);
    const onModalBtnClick = (type) => {
        setModalType(type);
        setModalOpen(true);
    };
    const [tabValue, setTabValue] = useState(0);
    const [accessoriesTabValue, setAccessoriesTabValue] = useState(0);
    return (
        <>
            <Meta
                title={props.metaTitle}
                keywords={props.metaKeywords}
                description={props.metaDescription}
            />
            <MainLayout
                navLogo={props.navLogo}
                navLogoWidth={props.navLogoWidth}
                navLogoHeight={props.navLogoHeight}
                navRoutes={props.navRoutes}
                navTransparent={props.navTransparent}
                footerTitle={props.footerTitle}
                footerTrademark={props.footerTrademark}
                footerImage={props.footerImage}
                footerImageWidth={props.footerImageWidth}
                footerImageHeight={props.footerImageHeight}
                footerPrimaryNav={props.footerPrimaryNav}
                footerSecondaryNav={props.footerSecondaryNav}
                footerLocations={props.footerLocations}
            >
                <Header
                    title={props.aagLocation.city}

                    subtitle={
                        props.showsimpleinfo
                            ? ``
                            : `${props.aagLocation.address_line_1}<br/>${props.aagLocation.address_line_2}`
                    }
                    imgUrl={imgUrl}
                    imgAlt={
                        props.aagLocation.image !== null
                            ? props.aagLocation.image.alternativeText
                            : `default location image`
                    }
                    imgHeight={
                        props.aagLocation.image !== null
                            ? props.aagLocation.image.height
                            : 492
                    }
                    imgWidth={
                        props.aagLocation.image !== null
                            ? props.aagLocation.image.width
                            : 789
                    }
                    imgEllipsesStyle="translate_down"
                    reactImage={reactImage}
                >
                    <div className={classes.links}>
                        {!props.hidePhone && (
                            <a
                                id={`tel_${props.aagLocation.city}`}
                                href={`tel:+1${props.aagLocation.phone_number.replace(
                                    /-/g,
                                    ``
                                )}`}
                                className={clsx(classes.link, classes.phoneLink)}
                            >
                                {props.aagLocation.phone_number}
                            </a>
                        )}

                        {!props.showsimpleinfo && props.showEmail && (
                            <Button
                                className={clsx(
                                    classes.link,
                                    classes.emailLink
                                )}
                                startIcon={
                                    <span
                                        className={classes.emailIconContainer}
                                    >
                                        <EmailIcon />
                                    </span>
                                }
                                component={`a`}
                                href={`mailto:+${props.email}`}
                            >
                                {props.email}
                            </Button>
                        )}

                        {!props.showsimpleinfo && !props.showEmail && (
                            <Button
                                className={classes.link}
                                startIcon={
                                    <img
                                        src={InfoIcon}
                                        alt=""
                                        width={32}
                                        height={32}
                                    />
                                }
                                disabled={true}
                                component={`span`}
                            >
                                {props.aagLocation.fax_number}
                            </Button>
                        )}
                    </div>
                </Header>
                <div className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <Typography variant="h1" className={classes.title}>
                                {!props.aagLocation.custom_heading && (
                                    <>
                                    {props.aagLocation.city} Auto Glass Repair &amp;
                                    Replacement
                                    </>
                                )}
                                {props.aagLocation.custom_heading && (
                                    <>
                                    {props.aagLocation.custom_heading}
                                    </>
                                )}
                            </Typography>
                            {!props.enableCustomMessage && (
                                <>
                                <Typography variant="body1" className={props.showsimpleinfo && (classes.halfwidth)}>
                                    {props.aagLocation.location_info}
                                </Typography>
                                <Typography variant="body1" className={classes.short}>
                                    {props.aagLocation.location_info_2}
                                </Typography>
                                </>
                            )}
                            {props.enableCustomMessage && (
                                <ReactMarkdown className={classes.customLink}>{props.aagLocation.custom_message}</ReactMarkdown>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            className={classes.rightContainer}
                        >
                            {!props.showsimpleinfo && (
                                <>
                                <Button
                                    startIcon={
                                        <img
                                            src={CalendarIcon}
                                            alt=""
                                            width={16}
                                            height={18}
                                        />
                                    }
                                    className={classes.btn}
                                    onClick={() => onModalBtnClick(`appointment`)}
                                >
                                    Book Appointment
                                </Button>
                                <Button
                                    startIcon={
                                        <img
                                            src={CalculatorIcon}
                                            alt=""
                                            width={16}
                                            height={18}
                                        />
                                    }
                                    className={classes.btn}
                                    onClick={() => onModalBtnClick(`quote`)}
                                >
                                    Request Quote
                                </Button>
                                </>

                            )}
                            <Button
                                startIcon={
                                    <img
                                        src={ShareIcon}
                                        alt=""
                                        width={25}
                                        height={25}
                                    />
                                }
                                className={clsx(classes.btn, classes.btnShare)}
                                component={`a`}
                                href={`https://www.facebook.com/sharer/sharer.php?u=appleautoglass.com/windshield-repair/${props.aagLocation.city
                                    .toLocaleLowerCase()
                                    .replace(/ /g, `-`)}`}
                                target="_blank"
                                rel="noopener"
                            >
                                Share This Page
                            </Button>

                            <FbLinkButton
                                fbLink={props.aagLocation.facebook_url}
                            ></FbLinkButton>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.container}>
                    <Grid container spacing={3}>
                        {!props.hideMap && (
                            <Grid item xs={12} lg={8}>
                                {props.showsimpleinfo ? (
                                    <GoogleMap
                                        defaultZoom={10}
                                        defaultCenter={[
                                            props.aagLocation.latitude,
                                            props.aagLocation.longitude,
                                        ]}
                                    />
                                ) : (
                                    <GoogleMap
                                        defaultZoom={14}
                                        defaultCenter={[
                                            props.aagLocation.latitude,
                                            props.aagLocation.longitude,
                                        ]}
                                        markers={[
                                            {
                                                lat: props.aagLocation.latitude,
                                                lng: props.aagLocation.longitude,
                                            },
                                        ]}
                                    />
                                )}
                            </Grid>
                        )}
                        {!props.hideOperatingHours && (
                            <Grid
                                item
                                xs={12}
                                lg={4}
                                className={clsx(
                                    classes.rightContainer,
                                    classes.hours
                                )}
                            >
                                <Typography className={classes.hoursTitle}>
                                    {props.aagLocation.city} Hours of Operation
                                </Typography>
                                {props.aagLocation.operating_hours.map((x, i) => (
                                    <React.Fragment key={x.day_of_the_week}>
                                        {x.opening_time &&
                                        x.closing_time &&
                                        x.status === `Open` ? (
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    className={classes.letterWidth}
                                                >
                                                    <span
                                                        className={classes.letter}
                                                    >
                                                        {x.day_of_the_week[0]}
                                                    </span>{" "}
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography
                                                        variant="body1"
                                                        className={classes.hour}
                                                    >
                                                        {formatTime(x.opening_time)}{" "}
                                                        -{" "}
                                                        {formatTime(x.closing_time)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    className={classes.letterWidth}
                                                >
                                                    <span
                                                        className={classes.letter}
                                                    >
                                                        {x.day_of_the_week[0]}
                                                    </span>{" "}
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography
                                                        variant="body1"
                                                        className={classes.hour}
                                                    >
                                                        <span
                                                            className={
                                                                classes.status
                                                            }
                                                        >
                                                            {x.status.replace(
                                                                /_/g,
                                                                ` `
                                                            )}
                                                        </span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </div>
                {(props.showWindshield ||
                    props.showGlass ||
                    props.showUpholstery) && (
                    <div
                        className={clsx(
                            classes.container,
                            classes.infoContainer
                        )}
                    >
                        <Hidden mdDown>
                            <AppBar
                                position="static"
                                className={classes.tabContainer}
                            >
                                <Tabs
                                    value={tabValue}
                                    onChange={(event, newValue) =>
                                        setTabValue(newValue)
                                    }
                                    aria-label="service tabs"
                                >
                                    {props.showWindshield && (
                                        <Tab
                                            label={
                                                <Typography
                                                    variant="h2"
                                                    className={classes.tabText}
                                                >
                                                    Windshield
                                                </Typography>
                                            }
                                            id="WindshieldTab"
                                            aria-controls="WindshieldTabPanel"
                                            className={classes.tab}
                                            disabled={
                                                !!props.showGlass === false &&
                                                !!props.showUpholstery === false
                                            }
                                        />
                                    )}
                                    {props.showGlass && (
                                        <Tab
                                            label={
                                                <Typography
                                                    variant="h2"
                                                    className={classes.tabText}
                                                >
                                                    Other Glass Service
                                                </Typography>
                                            }
                                            id="GlassTab"
                                            aria-controls="GlassTabPanel"
                                            className={classes.tab}
                                            disabled={
                                                !!props.showWindshield ===
                                                    false &&
                                                !!props.showUpholstery === false
                                            }
                                        />
                                    )}
                                    {props.showUpholstery && (
                                        <Tab
                                            label={
                                                <Typography
                                                    variant="h2"
                                                    className={classes.tabText}
                                                >
                                                    Upholstery Service
                                                </Typography>
                                            }
                                            id="UpholsteryTab"
                                            aria-controls="UpholsteryTabPanel"
                                            className={classes.tab}
                                            disabled={
                                                !!props.showGlass === false &&
                                                !!props.showWindshield === false
                                            }
                                        />
                                    )}
                                </Tabs>
                            </AppBar>
                            {props.showWindshield && (
                                <TabPanel
                                    value={tabValue}
                                    id="WindshieldTabPanel"
                                    labelledById="WindshieldTab"
                                    index={0}
                                >
                                    <RichText className={classes.richText}>
                                        {props.windshieldText}
                                    </RichText>
                                </TabPanel>
                            )}
                            {props.showGlass && (
                                <TabPanel
                                    value={tabValue}
                                    id="GlassTabPanel"
                                    labelledById="GlassTab"
                                    index={props.showWindshield ? 1 : 0}
                                >
                                    <RichText className={classes.richText}>
                                        {props.glassText}
                                    </RichText>
                                </TabPanel>
                            )}
                            {props.showUpholstery && (
                                <TabPanel
                                    value={tabValue}
                                    id="UpholsteryTabPanel"
                                    labelledById="UpholsteryTab"
                                    index={
                                        props.showWindshield && props.showGlass
                                            ? 2
                                            : props.showWindshield ||
                                              props.showGlass
                                            ? 1
                                            : 0
                                    }
                                >
                                    <RichText className={classes.richText}>
                                        {props.upholsteryText}
                                    </RichText>
                                </TabPanel>
                            )}
                        </Hidden>
                        <Hidden lgUp>
                            {props.showWindshield && (
                                <Question
                                    secondary
                                    data={{
                                        question: `Windshield`,
                                        answer: props.windshieldText,
                                    }}
                                />
                            )}
                            {props.showGlass && (
                                <Question
                                    secondary
                                    data={{
                                        question: `Other Glass Service`,
                                        answer: props.glassText,
                                    }}
                                />
                            )}
                            {props.showUpholstery && (
                                <Question
                                    secondary
                                    data={{
                                        question: `Upholstery Service`,
                                        answer: props.upholsteryText,
                                    }}
                                />
                            )}
                        </Hidden>
                    </div>
                )}
                {!props.showsimpleinfo && props.showAccessories && (
                    <div
                        className={clsx(
                            classes.container,
                            classes.infoContainer
                        )}
                    >
                        <Hidden mdDown>
                            <AppBar
                                position="static"
                                className={classes.tabContainer}
                            >
                                <Tabs
                                    value={accessoriesTabValue}
                                    onChange={(event, newValue) =>
                                        setAccessoriesTabValue(newValue)
                                    }
                                    aria-label="accessories tabs"
                                >
                                    <Tab
                                        label={
                                            <Typography
                                                variant="h2"
                                                className={classes.tabText}
                                            >
                                                Accessories
                                            </Typography>
                                        }
                                        id="AccessoriesTab"
                                        aria-controls="AccessoriesTabPanel"
                                        className={classes.tab}
                                        disabled={true}
                                    />
                                </Tabs>
                            </AppBar>
                            <TabPanel
                                value={accessoriesTabValue}
                                id="AccessoriesTabPanel"
                                labelledById="AccessoriesTab"
                                index={0}
                            >
                                <RichText className={classes.richText}>
                                    {props.accessoriesText}
                                </RichText>
                            </TabPanel>
                        </Hidden>
                        <Hidden lgUp>
                            <Question
                                secondary
                                data={{
                                    question: `Accessories`,
                                    answer: props.accessoriesText,
                                }}
                            />
                        </Hidden>
                    </div>
                )}
                <div className={classes.container}>
                    <ButtonComponent
                        data={{
                            title: `Looking for a different location?`,
                            text: `Find a Location Near You`,
                        }}
                    />
                </div>
                <Modal
                    type={modalType}
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    city={props.aagLocation.city}
                    operatingHours={props.aagLocation.operating_hours}
                />
            </MainLayout>
        </>
    );
}

Location.getInitialProps = async (ctx) => {
    let city = ctx.match.params.city;
    if (city.includes("-")) {
        city = city.replace(/-/g, ` `);
        city = city.split(` `);
        for (let i = 0; i < city.length; i++) {
            city[i] = city[i][0].toLocaleUpperCase() + city[i].substr(1);
        }
        city = city.join(` `);
    } else {
        city = city[0].toLocaleUpperCase() + city.substr(1);
    }
    const locationsRes = await RestService.get(`/locations?city=${city}`);
    if (locationsRes.data.length === 0) return { redirectTo: `/` };
    // redirect if closed
    if (locationsRes.data[0].closed === true) return { redirectTo: `/stores/` };

    const prelimData = await DataService.getPrelimData();

    const serviceTypesRes = await RestService.get(`/service-types`);
    const services = serviceTypesRes.data.map((x) => {
        return { id: x.id, type: x.type, details: x.details, services: [] };
    });
    for (const service of locationsRes.data[0].services) {
        const i = services.findIndex((x) => x.id === service.service_type);
        if (i !== -1) services[i].services.push(service.label);
    }

    const windshield = services.find((x) => x.type === `Windshield`);
    const showWindshield = windshield.services.length > 0;
    const windshieldText =
        windshield.services.length > 0
            ? `${windshield.details}  \n- ${windshield.services.join(`  \n- `)}`
            : `${windshield.details}`;

    const glass = services.find((x) => x.type === `Glass`);
    const showGlass = glass.services.length > 0;
    const glassText =
        glass.services.length > 0
            ? `${glass.details}  \n- ${glass.services.join(`  \n- `)}`
            : `${glass.details}`;

    const upholstery = services.find((x) => x.type === `Upholstery`);
    const showUpholstery = upholstery.services.length > 0;
    const upholsteryText =
        upholstery.services.length > 0
            ? `${upholstery.details}  \n- ${upholstery.services.join(`  \n- `)}`
            : `${upholstery.details}`;

    const accessories = services.find((x) => x.type === `Accessories`);
    const showAccessories = accessories.services.length > 0;
    const accessoriesText =
        accessories.services.length > 0
            ? `${accessories.details}  \n- ${accessories.services.join(
                  `  \n- `
              )}`
            : `${accessories.details}`;

    const jsRes = await RestService.get(`/js`);
    return {
        metaTitle: locationsRes.data[0].metadata.meta_title,
        metaKeywords: locationsRes.data[0].metadata.meta_keywords,
        metaDescription: locationsRes.data[0].metadata.meta_description,
        navTransparent: true,
        ...prelimData,
        city,
        cityLink: ctx.match.params.city,
        aagLocation: locationsRes.data[0],
        services,
        windshieldText,
        glassText,
        upholsteryText,
        accessoriesText,
        showWindshield,
        showGlass,
        showUpholstery,
        showAccessories,
        email:
            locationsRes.data[0].contacts.length > 0
                ? locationsRes.data[0].contacts[0].email
                : `No Contact Email`,
        showEmail: locationsRes.data[0].display_email_instead_of_fax_number,
        hidePhone: locationsRes.data[0].hide_phone_number,
        hideOperatingHours: locationsRes.data[0].hide_operating_hours,
        hideMap: locationsRes.data[0].hide_map,
        enableCustomMessage: locationsRes.data[0].enable_custom_message,
        showsimpleinfo: locationsRes.data[0].showing_simple_info,
        structuredData: locationsRes.data[0].metadata.structured_data,
        headScriptContent: jsRes.data.head,
        bodyScriptContent: jsRes.data.body,
        headNoScriptContent: jsRes.data.head_noscript,
        bodyNoScriptContent: jsRes.data.body_noscript,
    };
};

export default Location;
