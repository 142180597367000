import React, { useEffect } from "react";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ensureReady, After } from "@jaredpalmer/after";
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "./theme";
import routes from "./routes";

function Main({ data }) {
    useEffect(() => {
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles) jssStyles.parentElement.removeChild(jssStyles);
    }, []);
    return (
        <BrowserRouter>
            {/* Add all context providers to the client. */}

            <ThemeProvider theme={theme}>
                <After data={data} routes={routes} />
            </ThemeProvider>
        </BrowserRouter>
    );
}

ensureReady(routes).then((data) =>
    hydrate(<Main data={data} />, document.getElementById("root"))
);

if (module.hot) {
    module.hot.accept();
}
