import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

import RichText from "../../main/RichText";

function Highlight(props) {
    return (
        <Grid item xs={12} className={props.className}>
            <RichText>{props.children}</RichText>
        </Grid>
    );
}

Highlight.propTypes = {
    className: PropTypes.string,
    children: PropTypes.string.isRequired,
};

export default Highlight;
