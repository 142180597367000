import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

import RichText from "../../main/RichText";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0 16rem",
        margin: "4rem 0",
        [theme.breakpoints.down("lg")]: {
            padding: "0 12rem",
        },
        [theme.breakpoints.down("md")]: {
            padding: "0 8rem",
            margin: "2rem 0",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0 4rem",
            margin: "1rem 0",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0 2rem",
            margin: "0 0",
        },
    },
    title: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 40,
        lineHeight: "50px",
        color: theme.palette.primary.main,
        borderBottom: `solid thin ${theme.palette.primary.main}`,
        paddingBottom: "0.5rem",
    },
    text: {
        "& a": {
            color: theme.palette.primary.main,
        },
    },
}));

function Article(props) {
    const classes = useStyles();
    return (
        <article className={classes.root}>
            <Typography variant="h1" className={classes.title}>
                {props.data.title}
            </Typography>
            <RichText className={classes.text}>{props.data.content}</RichText>
        </article>
    );
}

Article.propTypes = {
    data: PropTypes.any,
};

export default Article;
