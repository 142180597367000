import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Grid } from "@material-ui/core";

import RichText from "../../main/RichText";

import Highlight from "./Highlight";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#eee",
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem 2rem",
        borderRadius: "0 0 20px 20px",
    },
    container: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    paragraph: {},
    left: {
        borderRadius: "0 0 20px 0",
        [theme.breakpoints.down("md")]: {
            borderRadius: "0 0 20px 20px",
        },
    },
    right: {
        borderRadius: "0 0 0 20px",
        [theme.breakpoints.down("md")]: {
            borderRadius: "0 0 20px 20px",
        },
    },
    bottom: {
        borderRadius: 0,
        [theme.breakpoints.down("md")]: {
            borderRadius: "0 0 20px 20px",
        },
    },
    leftPar: {
        marginRight: "0.5rem",
        [theme.breakpoints.down("md")]: {
            marginRight: 0,
        },
    },
    rightPar: {
        marginLeft: "0.5rem",
        [theme.breakpoints.down("md")]: {
            marginLeft: 0,
        },
    },
}));

function Body(props) {
    const classes = useStyles();
    const getClass = (loc) => {
        if (loc === `left`) {
            return classes.left;
        } else if (loc === `right`) {
            return classes.right;
        } else if (loc === `bottom`) {
            return classes.bottom;
        }
    };
    return (
        <Grid
            item
            xs={12}
            className={clsx(
                classes.root,
                props.className,
                getClass(props.location)
            )}
        >
            {props.highlight &&
                props.highlightPosition &&
                props.highlightPosition === `before_paragraphs` && (
                    <Highlight>{props.highlight}</Highlight>
                )}
            <div className={classes.container}>
                <Grid
                    item
                    xs={12}
                    lg={props.paragraph2 ? 6 : 12}
                    className={clsx(
                        classes.paragraph,
                        props.paragraph2 ? classes.leftPar : ``
                    )}
                >
                    <RichText>{props.paragraph1}</RichText>
                </Grid>
                {props.paragraph2 && (
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        className={clsx(classes.paragraph, classes.rightPar)}
                    >
                        <RichText>{props.paragraph2}</RichText>
                    </Grid>
                )}
            </div>
            {props.highlight &&
                props.highlightPosition &&
                props.highlightPosition === `after_paragraphs` && (
                    <Highlight>{props.highlight}</Highlight>
                )}
        </Grid>
    );
}

Body.propTypes = {
    className: PropTypes.string,
    paragraph1: PropTypes.string.isRequired,
    paragraph2: PropTypes.string,
    highlight: PropTypes.string,
    highlightPosition: PropTypes.string,
};

export default Body;
