// returns { latitude: number, longitude: number }
const getMyCoords = (navigator) => {
    return new Promise((resolve, reject) => {
        if (!navigator) reject(`navigator is required`);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            (error) => {
                reject(error);
            }
        );
    });
};

const calcMagnitude = (lat1, lng1, lat2, lng2) => {
    return Math.sqrt(Math.pow(lat2 - lat1, 2) + Math.pow(lng2 - lng1, 2));
};

// locations = [{ name: `Cambridge`, latitude: 43.407411155005796, longitude: -80.33018588456281 }],
// returns location name
const getNearestLocation = (latitude, longitude, locations) => {
    let closestLoc = null;
    let closestDist = Number.MAX_SAFE_INTEGER;
    for (const loc of locations) {
        const dist = calcMagnitude(
            latitude,
            longitude,
            loc.latitude,
            loc.longitude
        );
        if (dist < closestDist) {
            closestDist = dist;
            closestLoc = loc.name;
        }
    }
    return closestLoc;
};

const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
};

const getDistanceInKm = (lat1, lng1, lat2, lng2, round = false) => {
    const radiusOfEarth = 6371;
    const distanceLat = degreesToRadians(lat2 - lat1);
    const distanceLng = degreesToRadians(lng2 - lng1);
    const a =
        Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
        Math.cos(degreesToRadians(lat1)) *
            Math.cos(degreesToRadians(lat2)) *
            Math.sin(distanceLng / 2) *
            Math.sin(distanceLng / 2);
    const b = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInKm = radiusOfEarth * b;
    return round ? Math.round(distanceInKm) : distanceInKm;
};

const getGoogleMapsDirectionsLink = (destination) => {
    return `http://maps.google.com/?q=Apple%20Auto%20Glass%20${destination.replace(
        / /g,
        `%20`
    )}`;
};

export default {
    getMyCoords,
    getNearestLocation,
    getDistanceInKm,
    getGoogleMapsDirectionsLink,
};
