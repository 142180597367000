import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles, Typography, Button } from "@material-ui/core";

import PhoneIcon from "../../assets/icons/phone-green.svg";
import InfoIcon from "../../assets/icons/info-green.svg";
import CalendarIcon from "../../assets/icons/calendar-black.svg";
import CalculatorIcon from "../../assets/icons/calculator-black.svg";
import PinIcon from "../../assets/icons/pin-red.svg";

import Modal from "../../components/main/Modal";

import LocationService from "../../services/LocationService";

import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        borderBottom: "solid thin #111",
        height: "100%",
    },
    title: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 40,
        lineHeight: "50px",
    },
    btn: {
        textTransform: "unset",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        justifyContent: "flex-start",
        padding: 0,
        marginBottom: 10,
    },
    btnPrimary: {
        color: theme.palette.primary.main,
        padding: 0,
        fontSize: 24,
        lineHeight: "34px",
    },
    btnSecondary: {
        color: "#111",
        textTransform: "uppercase",
        fontSize: 20,
        lineHeight: "24px",
    },
    btnMore: {
        color: "#111",
        fontFamily: `"Palanquin", "Helvetica", sans-serif`,
        fontSize: 20,
        lineHeight: "34px",
        textDecoration: "underline",
    },
    btnDirections: {
        color: theme.palette.primary.main,
        padding: 0,
        justifyContent: "flex-start",
        textTransform: "uppercase",
        textDecoration: "underline",
    },
    mb: {
        marginBottom: 16,
    },
    icon: {
        marginLeft: 5,
        marginRight: 5,
    },
    addText: {
        lineHeight: "24px",
    },
    distText: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "20px",
        paddingLeft: 12,
        paddingBottom: 8,
    },
    pinIcon: {
        marginRight: "0.5rem",
    },
    pinText: {
        color: "#fff",
        marginRight: -15,
        marginTop: -10,
        zIndex: 9,
    },
    pinTextDD: {
        marginRight: -18,
    },
    phoneLink: {
        textDecoration: "unset",
        display: "flex",
        alignItems: "center",
        "&::before": {
            content: `url(${PhoneIcon})`,
            width: 25,
            height: 25,
            marginRight: 13,
        },
    },
    shortinfo: {
        maxWidth: 326,
        marginBottom: 10,
    },
    smallshortinfo: {
        fontSize: 10,
        maxWidth: 326,
        marginBottom: 25,
    },
    customLink: {
        fontSize: "1rem",
        fontFamily: `"Palanquin", "Helvetica", sans-serif`,
        fontWeight: 400,
        lineHeight: "24px",
        maxWidth: 326,
        marginBottom: 10,
        "& a": {
            color: "#00863F",
            textDecoration: "unset",
            "&:hover": {
                color: "#11111150",
            },
        }
    },
}));

function Location(props) {
    const classes = useStyles();
    const [modalType, setModalType] = useState(`appointment`);
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <div className={clsx(classes.root, props.className)}>
            {props.distance && (
                <Typography
                    variant="body1"
                    className={clsx(classes.addText, classes.distText)}
                >
                    <label
                        className={clsx(
                            classes.pinText,
                            props.index >= 9 ? classes.pinTextDD : ``
                        )}
                    >
                        {props.index + 1}
                    </label>
                    <img
                        src={PinIcon}
                        alt=""
                        className={classes.pinIcon}
                        width={22}
                        height={28}
                    />
                    Distance - {props.distance}KM
                </Typography>
            )}
            <Typography variant="body1" className={classes.title}>
                {props.city}
            </Typography>
            {props.showingsimpleinfo && (
                <>
                {!props.enablingCustomMessage && (
                    <>
                    <div className={classes.shortinfo}>
                        <Typography variant="body1" className={classes.shortinfo}>
                            {props.shortinfo}
                        </Typography>
                        <Typography variant="body1" className={classes.smallshortinfo}>
                            {props.shortinfo2}
                        </Typography>
                    </div>
                    </>
                )}
                {props.enablingCustomMessage && (
                    <ReactMarkdown className={classes.customLink}>{props.custommessage}</ReactMarkdown>
                )}
                </>
            )}
            {!props.showingsimpleinfo && (
                <>
                <Typography variant="body1" className={classes.addText}>
                    {props.addressLine1}
                </Typography>
                <Typography variant="body1" className={classes.addText}>
                    {props.addressLine2}
                </Typography>
                <Button
                    component={"a"}
                    href={LocationService.getGoogleMapsDirectionsLink(
                        `${props.addressLine1} ${props.addressLine2}`
                    )}
                    rel="noreferrer"
                    target="_blank"
                    className={clsx(classes.btnDirections, classes.mb)}
                >
                    Get Directions
                </Button>
                </>
            )}
            {!props.hidephone && (
                <a
                    id={`tel_${props.city}`}
                    href={`tel:+1${props.phone.replace(/-/g, ``)}`}
                    className={clsx(
                        classes.btn,
                        classes.btnPrimary,
                        classes.phoneLink
                    )}
                >
                    {props.phone}
                </a>
            )}
            <Button
                component={Link}
                to={props.url}
                startIcon={
                    <img
                        src={InfoIcon}
                        alt=""
                        className={classes.icon}
                        width={25}
                        height={25}
                    />
                }
                className={clsx(classes.btn, classes.btnMore, classes.mb)}
            >
                More about this location
            </Button>
            {!props.showingsimpleinfo && (
                <>
                <Button
                    startIcon={
                        <img
                            src={CalendarIcon}
                            alt=""
                            className={classes.icon}
                            width={25}
                            height={28}
                        />
                    }
                    onClick={() => {
                        setModalType(`appointment`);
                        setModalOpen(true);
                    }}
                    className={clsx(classes.btn, classes.btnSecondary)}
                >
                    Book Appointment
                </Button>
                <Button
                    startIcon={
                        <img
                            src={CalculatorIcon}
                            alt=""
                            className={classes.icon}
                            width={25}
                            height={28}
                        />
                    }
                    onClick={() => {
                        setModalType(`quote`);
                        setModalOpen(true);
                    }}
                    className={clsx(classes.btn, classes.btnSecondary, classes.mb)}
                >
                    Request Quote
                </Button>
                </>
            )}
            <Modal
                type={modalType}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                city={props.city}
            />
        </div>
    );
}

Location.propTypes = {
    className: PropTypes.string,
    city: PropTypes.string.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
};

export default Location;
