import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SmartLink from "../Navigation/SmartLink";
import { makeStyles, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    link: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontWeight: "bold",
        color: "#111",
        textDecoration: "none",
        transition: "0.25s ease-in-out",
        padding: "0 1rem",
        "&:hover": {
            color: "#11111150",
        },
        [theme.breakpoints.down("sm")]: {
            display: "block",
            padding: "0.5rem 0",
        },
    },
}));

function PrimaryNav(props) {
    const classes = useStyles();
    return (
        <div className={clsx(props.className, classes.root)}>
            {props.data.map((x) => (
                <Typography key={x.to} variant="body1">
                    <SmartLink to={x.to} className={classes.link} text={x.text} />
                </Typography>
            ))}
        </div>
    );
}

PrimaryNav.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
};

export default PrimaryNav;
