import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function SmartLink(props) {
    return /^https?:\/\//.test(props.to) ? (
        <a href={props.to} class={props.className} target="_blank">
            {props.text}
        </a>
    ) : (
        <Link key={props.to} to={props.to} className={props.className}>
            {props.text}
        </Link>
    );
}

SmartLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default SmartLink;
