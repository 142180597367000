import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "1rem 0",
    },
    link: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        color: "#00863F",
        textDecoration: "none",
        transition: "0.25s ease-in-out",
        "&:hover": {
            color: "#11111150",
        },
    },
}));

function SimpleLocation(props) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, props.className)}>
            <Link
                className={classes.link}
                to={`/windshield-repair/${props.city
                    .toLocaleLowerCase()
                    .replace(/ /g, `-`)}`}
            >
                {props.city}
            </Link>
            <Typography variant="body1">{props.address}</Typography>
        </div>
    );
}

SimpleLocation.propTypes = {
    className: PropTypes.string,
    city: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
};

export default SimpleLocation;
