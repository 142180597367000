import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from "@material-ui/core";
import { ExpandMore as ExpandIcon } from "@material-ui/icons";

import RichText from "../../main/RichText";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "4rem 4rem",
        [theme.breakpoints.down("lg")]: {
            margin: "3rem 4rem",
        },
        [theme.breakpoints.down("md")]: {
            margin: "2rem 4rem",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "1rem 4rem",
        },
        [theme.breakpoints.down("xs")]: {
            margin: "1rem 2rem",
        },
    },
    accordion: {
        boxShadow: "unset",
        margin: 0,
    },
    summary: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        borderRadius: 20,
        transition: "276ms",
        padding: 0,
        paddingRight: 30,
    },
    summaryExpanded: {
        borderRadius: "20px 20px 0 0",
    },
    details: {
        backgroundColor: "#eee",
        borderRadius: "0 0 20px 20px",
        padding: "20px 30px",
    },
    icon: {
        color: "#fff",
        fontSize: "3rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem",
        },
    },
    title: {
        paddingLeft: 30,
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontWeight: 400,
        fontSize: 23,
        lineHeight: "38px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 20,
            lineHeight: "28px",
        },
    },
    text: {
        fontSize: 20,
        lineHeight: "26px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 16,
            lineHeight: "20px",
        },
    },
    secondaryNotExpanded: {
        backgroundColor: "#111",
    },
}));

function Question(props) {
    const classes = useStyles();
    const [expand, setExpand] = useState(false);
    return (
        <div className={classes.root}>
            <Accordion
                expanded={expand}
                onChange={() => setExpand(!expand)}
                className={classes.accordion}
            >
                <AccordionSummary
                    aria-controls="panel content"
                    expandIcon={<ExpandIcon className={classes.icon} />}
                    className={clsx(
                        classes.summary,
                        expand ? classes.summaryExpanded : ``,
                        props.secondary && !expand
                            ? classes.secondaryNotExpanded
                            : ``
                    )}
                >
                    <Typography variant="h2" className={classes.title}>
                        {props.data.question}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <RichText className={classes.text}>
                        {props.data.answer}
                    </RichText>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

Question.propTypes = {
    data: PropTypes.any,
    secondary: PropTypes.bool,
};

export default Question;
