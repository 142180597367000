import NotFound from "./pages/NotFound";
import Page from "./pages/Page";
import Locations from "./pages/Locations";
import Location from "./pages/Location";

const routes = [
    {
        path: "/stores",
        exact: true,
        component: Locations,
    },
    {
        path: "/windshield-repair/:city",
        exact: true,
        component: Location,
    },
    {
        path: "/not-found",
        exact: true,
        component: NotFound,
    },
    {
        path: "/",
        exact: true,
        component: Page,
    },
    {
        path: "/:friendlyUrl",
        exact: true,
        component: Page,
    },
    {
        component: NotFound,
    },
];

export default routes;
