import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        margin: "4rem 0",
        padding: "0 10rem",
        [theme.breakpoints.down("lg")]: {
            padding: "0 4rem",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0 1rem",
            flexDirection: "column",
        },
    },
    letterContainer: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        borderRadius: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "6.6rem",
        maxWidth: "6.6rem",
        height: "6rem",
        paddingBottom: "0.6rem",
        marginRight: "1rem",
        [theme.breakpoints.down("sm")]: {
            alignSelf: "flex-start",
            marginBottom: "1rem",
        },
    },
    title: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 28,
        lineHeight: "38px",
    },
    text: {
        fontSize: 20,
        lineHeight: "26px",
    },
    letter: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 80,
        lineHeight: "64px",
    },
}));

function Point(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.letterContainer}>
                <Typography className={classes.letter}>
                    {props.data.letter.toLocaleUpperCase()}
                </Typography>
            </div>
            <div>
                <Typography className={classes.title}>
                    {props.data.title}
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    {props.data.content}
                </Typography>
            </div>
        </div>
    );
}

Point.propTypes = {
    data: PropTypes.any,
};

export default Point;
