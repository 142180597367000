import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

import { Button as MyButton } from "../../../tags";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        margin: "4rem 1rem",
        [theme.breakpoints.down("xs")]: {
            margin: "2rem 1rem",
        },
    },
    title: {
        marginBottom: "1rem",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 28,
        lineHeight: "38px",
        [theme.breakpoints.down("md")]: {
            fontSize: 20,
            lineHeight: "28px",
        },
    },
}));

function Button(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.data.title && (
                <Typography
                    variant={
                        props.data.title ===
                        `Want to learn more about our recalibration service?`
                            ? `h2`
                            : `body1`
                    }
                    className={classes.title}
                >
                    {props.data.title}
                </Typography>
            )}
            <MyButton link>{props.data.text}</MyButton>
        </div>
    );
}

Button.propTypes = {
    data: PropTypes.any,
};

export default Button;
