import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { makeStyles } from "@material-ui/core";

import { GOOGLE_API_KEY, DEFAULT_CENTER, DEFAULT_ZOOM } from "../../Constants";

import Marker from "./Marker";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        width: "100%",
        height: 400,
        [theme.breakpoints.down("sm")]: {
            height: 300,
        },
        [theme.breakpoints.down("xs")]: {
            height: 250,
        },
    },
    large: {
        height: 600,
        [theme.breakpoints.down("sm")]: {
            height: 400,
        },
        [theme.breakpoints.down("xs")]: {
            height: 300,
        },
    },
}));

const Wrapper = (props) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, props.large ? classes.large : ``)}>
            {props.children}
        </div>
    );
};

const GoogleMap = ({ children, ...props }) => {
    const getZoom = () => {
        const width = window.outerWidth || 599;
        if (width < 600) {
            return DEFAULT_ZOOM;
        } else if (width < 900) {
            return 2;
        } else if (width < 1200) {
            return 3;
        } else {
            return 4;
        }
    };
    return (
        <Wrapper large={props.large}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: GOOGLE_API_KEY,
                }}
                defaultCenter={props.defaultCenter}
                {...props}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                    if (props.markers.length === 1) {
                        props.markers.map((x) => {
                            new maps.Marker({
                                position: {
                                    lat: parseFloat(x.lat),
                                    lng: parseFloat(x.lng),
                                },
                                map,
                            });
                        });
                    }
                    if (props.setZoom) {
                        const zoomLevel = getZoom();
                        props.setZoom(zoomLevel);
                    }
                }}
            >
                {props.markers.length > 1 &&
                    props.markers.map((x, i) => {
                        return (
                            <Marker
                                key={`${x.lat}_${x.lng}`}
                                lat={parseFloat(x.lat)}
                                lng={parseFloat(x.lng)}
                                disabled={false}
                                text={props.markers.length > 1 ? i + 1 : ``}
                                to={
                                    props.markers.length > 1 && x.city
                                        ? `/windshield-repair/${x.city
                                              .toLocaleLowerCase()
                                              .replace(/ /g, `-`)}`
                                        : ``
                                }
                            />
                        );
                    })}
            </GoogleMapReact>
        </Wrapper>
    );
};

GoogleMap.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    defaultZoom: PropTypes.number,
    defaultCenter: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]),
    large: PropTypes.bool,
    markers: PropTypes.array,
    numbered: PropTypes.bool,
};

GoogleMap.defaultProps = {
    children: null,
    defaultCenter: DEFAULT_CENTER,
    large: false,
    markers: [],
    numbered: false,
};

export default withRouter(GoogleMap);
