import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Typography, Grid } from "@material-ui/core";

import { IMAGE_API_URI } from "../../../Constants";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 5,
    },
    image: {
        maxHeight: 65,
        width: "auto",
        marginBottom: 16,
        [theme.breakpoints.down("xs")]: {
            marginTop: 20,
        },
    },
}));

function Trademark(props) {
    const classes = useStyles();
    return (
        <>
            <Grid
                container
                justify="center"
                alignItems="center"
                className={clsx(classes.root, props.className)}
            >
                <img
                    src={`${IMAGE_API_URI}${props.image}`}
                    alt=""
                    className={classes.image}
                    width={props.imageWidth}
                    height={props.imageHeight}
                />
            </Grid>
            <Grid
                container
                justify="center"
                alignItems="center"
                className={clsx(classes.root, props.className)}
            >
                <Typography variant="body1">{props.trademark}</Typography>
            </Grid>
        </>
    );
}

Trademark.propTypes = {
    className: PropTypes.string,
    image: PropTypes.string.isRequired,
    trademark: PropTypes.string.isRequired,
};

export default Trademark;
