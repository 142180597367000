import RestService from "../services/RestService";

const getPrelimData = async () => {
    const navigationRes = await RestService.get(`/navigation`);
    const navRoutes = [];
    for (const section of navigationRes.data.sections) {
        navRoutes.push({
            label: section.label,
            pages: section.pages.map((x) => {
                return {
                    to: x.friendly_url,
                    text: x.friendly_name,
                };
            }),
        });
    }
    const logoRes = await RestService.get(`/logo`);
    const footerRes = await RestService.get(`/footer`);
    const locationsRes = await RestService.get(`/locations`);
    const footerLocations = [];
    for (let i = 0; i < locationsRes.data.length; i++) {
        const item = locationsRes.data[i];
        const provIndex = footerLocations.findIndex(
            (x) => x.province === item.province
        );
        if (provIndex !== -1) {
            footerLocations[provIndex].cities.push({
                city: item.city,
                address: item.address_line_1,
            });
        } else {
            footerLocations.push({
                province: item.province,
                cities: [{ city: item.city, address: item.address_line_1 }],
            });
        }
    }
    return {
        navRoutes,
        navLogo: logoRes.data.primary.url,
        navLogoWidth: logoRes.data.primary.width,
        navLogoHeight: logoRes.data.primary.height,
        footerTitle: footerRes.data.title,
        footerTrademark: footerRes.data.trademark,
        footerImage: logoRes.data.secondary.url,
        footerImageWidth: logoRes.data.secondary.width,
        footerImageHeight: logoRes.data.secondary.height,
        footerPrimaryNav: footerRes.data.primary_nav.map((x) => {
            return { to: x.friendly_url, text: x.friendly_name };
        }),
        footerSecondaryNav: footerRes.data.secondary_nav.map((x) => {
            if (x.redirect_url !== null) {
                return { to: x.redirect_url, text: x.friendly_name };
            }
            return { to: x.friendly_url, text: x.friendly_name };
        }),
        footerLocations,
    };
};

export default { getPrelimData };
