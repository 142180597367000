import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles, IconButton, Icon, Typography } from "@material-ui/core";

import MarkerIcon from "../../assets/icons/pin-red.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        color: "#ee1b2e",
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -100%)",
    },
    marker: {
        width: "1.5em",
        height: "1.5em",
    },
    lbl: {
        color: "#fff",
        position: "absolute",
        top: 12,
        left: 26,
    },
    lblLrg: {
        left: 22,
    },
}));

function Location(props) {
    const classes = useStyles();
    return (
        <>
            {props.to && props.to !== `` ? (
                <IconButton
                    className={clsx(classes.root, props.className)}
                    component={Link}
                    to={props.to}
                    disabled={props.disabled}
                >
                    <img
                        src={MarkerIcon}
                        alt=""
                        className={classes.marker}
                        width={36}
                        height={36}
                    />
                    {props.text && props.text !== `` && (
                        <Typography
                            variant="body1"
                            className={clsx(
                                classes.lbl,
                                parseInt(props.text) > 9 ? classes.lblLrg : ``
                            )}
                        >
                            {props.text}
                        </Typography>
                    )}
                </IconButton>
            ) : (
                <Icon
                    className={clsx(classes.root, props.className)}
                    disabled={props.disabled}
                >
                    <img
                        src={MarkerIcon}
                        alt=""
                        className={classes.marker}
                        width={36}
                        height={36}
                    />
                    {props.text && props.text !== `` && (
                        <Typography
                            variant="body1"
                            className={clsx(
                                classes.lbl,
                                parseInt(props.text) > 9 ? classes.lblLrg : ``
                            )}
                        >
                            {props.text}
                        </Typography>
                    )}
                </Icon>
            )}
        </>
    );
}

Location.propTypes = {
    className: PropTypes.string,
    to: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Location;
