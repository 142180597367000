import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

import PrimaryNav from "./PrimaryNav";
import SimpleLocations from "./SimpleLocations";
import Trademark from "./Trademark";
import SecondaryNav from "./SecondaryNav";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        borderTop: "solid thin black",
        margin: "0 4rem",
        marginTop: 75,
        padding: "2rem 0 6rem 0",
        [theme.breakpoints.down("sm")]: {
            margin: "0 2rem",
            marginTop: 75,
        },
    },
}));

function Footer(props) {
    const classes = useStyles();
    return (
        <footer className={clsx(classes.root, props.className)}>
            <PrimaryNav data={props.primaryNav} />
            <SimpleLocations title={props.title} locations={props.locations} />
            <Trademark
                image={props.image}
                imageWidth={props.imageWidth}
                imageHeight={props.imageHeight}
                trademark={props.trademark}
            />
            <SecondaryNav data={props.secondaryNav} />
        </footer>
    );
}

Footer.propTypes = {
    className: PropTypes.string,
    primaryNav: PropTypes.array,
    title: PropTypes.string.isRequired,
    locations: PropTypes.array,
    image: PropTypes.string.isRequired,
    imageHeight: PropTypes.number.isRequired,
    imageWidth: PropTypes.number.isRequired,
    trademark: PropTypes.string.isRequired,
    secondaryNav: PropTypes.array,
};

export default Footer;
