import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import Article from "../../components/generic/Article";
import Button from "../../components/generic/Button";
import Cards from "../../components/generic/Cards";
import Details from "../../components/generic/Details";
import Information from "../../components/generic/Information";
import Point from "../../components/generic/Point";
import Question from "../../components/generic/Question";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0 4rem",
        [theme.breakpoints.down("sm")]: {
            padding: "1rem 2rem",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "1rem 0",
        },
    },
}));

function Content(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.data.map((component, index) => {
                if (
                    component.__component &&
                    component.__component === `generic.article`
                ) {
                    return (
                        <Article
                            key={`${component.__component}_${index}`}
                            data={component}
                        />
                    );
                } else if (
                    component.__component &&
                    component.__component === `generic.button`
                ) {
                    return (
                        <Button
                            key={`${component.__component}_${index}`}
                            data={component}
                        />
                    );
                } else if (
                    component.__component &&
                    component.__component === `generic.cards`
                ) {
                    return (
                        <Cards
                            key={`${component.__component}_${index}`}
                            data={component}
                        />
                    );
                } else if (
                    component.__component &&
                    component.__component === `generic.info`
                ) {
                    return (
                        <Details
                            key={`${component.__component}_${index}`}
                            data={component}
                        />
                    );
                } else if (
                    component.__component &&
                    component.__component === `generic.information`
                ) {
                    return (
                        <Information
                            key={`${component.__component}_${index}`}
                            data={component}
                        />
                    );
                } else if (component.__component === `page.point`) {
                    return (
                        <Point
                            key={`${component.__component}_${index}`}
                            data={component}
                        />
                    );
                } else if (
                    component.__component &&
                    component.__component === `page.question`
                ) {
                    return (
                        <Question
                            key={`${component.__component}_${index}`}
                            data={component}
                        />
                    );
                }
            })}
        </div>
    );
}

Content.propTypes = {
    data: PropTypes.any,
    path: PropTypes.string,
};

export default Content;
