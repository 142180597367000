import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Grid } from "@material-ui/core";

import Image from "./Image";
import Header from "./Header";
import Body from "./Body";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "4rem 6rem",
        [theme.breakpoints.down("sm")]: {
            margin: "4rem 2rem",
        },
        width: "unset",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    container: {
        zIndex: 9,
        padding: "1rem",
        [theme.breakpoints.down("lg")]: {
            padding: 0,
        },
        [theme.breakpoints.down("md")]: {
            order: 1,
        },
    },
    containerLeft: {
        marginRight: -30,
        [theme.breakpoints.down("md")]: {
            marginRight: "0",
        },
    },
    containerRight: {
        marginLeft: -30,
        [theme.breakpoints.down("md")]: {
            marginLeft: "0",
        },
    },
    containerTop: {
        margin: "0 2.3rem -54px 2.3rem",
        [theme.breakpoints.down("md")]: {
            margin: 0,
        },
    },
    containerBottom: {
        margin: "-70px 2.3rem 0 2.3rem",
        [theme.breakpoints.down("lg")]: {
            margin: "-54px 2.3rem 0 2.3rem",
        },
        [theme.breakpoints.down("md")]: {
            margin: 0,
        },
    },
}));

function Information(props) {
    const classes = useStyles();
    const getContainerClass = (imageLocation) => {
        if (imageLocation === `left`) {
            return classes.containerRight;
        } else if (imageLocation === `right`) {
            return classes.containerLeft;
        } else if (imageLocation === `top`) {
            return classes.containerBottom;
        } else if (imageLocation === `bottom`) {
            return classes.containerTop;
        }
    };
    const image =
        props.data.image &&
        props.data.image.formats &&
        props.data.image.formats.small
            ? props.data.image.formats.small.url
            : props.data.image
            ? props.data.image.url
            : null;
    const imageWidth =
        props.data.image &&
        props.data.image.formats &&
        props.data.image.formats.small
            ? props.data.image.formats.small.width
            : props.data.image
            ? props.data.image.width
            : null;
    const imageHeight =
        props.data.image &&
        props.data.image.formats &&
        props.data.image.formats.small
            ? props.data.image.formats.small.height
            : props.data.image
            ? props.data.image.height
            : null;
    return (
        <Grid container className={classes.root}>
            {props.data.image &&
                (props.data.image_location === `left` ||
                    props.data.image_location === `top`) && (
                    <Image
                        src={image}
                        alt={props.data.image.alternativeText}
                        location={props.data.image_location}
                        width={imageWidth}
                        height={imageHeight}
                    />
                )}
            <Grid
                item
                xs={12}
                lg={
                    props.data.image_location === `right` ||
                    props.data.image_location === `left`
                        ? 6
                        : 12
                }
                className={clsx(
                    classes.container,
                    getContainerClass(props.data.image_location)
                )}
            >
                <Header
                    location={props.data.image_location}
                    cta={props.data.cta}
                    level={props.data.title_level}
                >
                    {props.data.title}
                </Header>
                <Body
                    paragraph1={props.data.paragraph_1}
                    paragraph2={props.data.paragraph_2}
                    highlight={props.data.highlight}
                    highlightPosition={props.data.highlight_position}
                    location={props.data.image_location}
                />
            </Grid>
            {props.data.image &&
                (props.data.image_location === `right` ||
                    props.data.image_location === `bottom`) && (
                    <Image
                        src={image}
                        alt={props.data.image.alternativeText}
                        location={props.data.image_location}
                        width={imageWidth}
                        height={imageHeight}
                    />
                )}
        </Grid>
    );
}

Information.propTypes = {
    data: PropTypes.any,
};

export default Information;
