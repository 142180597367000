import axios from "axios";

import { API_URI } from "../Constants";

const HttpVerbs = Object.freeze({
    GET: Symbol("get"),
    POST: Symbol("post"),
    PUT: Symbol("put"),
    DELETE: Symbol("delete"),
});

const req = async (endpoint, verb = HttpVerbs.GET, data = null) => {
    const baseUrl = API_URI || `http://localhost:1337`;
    const url = `${baseUrl}${endpoint}`;
    let response = { data: null, status: 400 };
    try {
        if (verb === HttpVerbs.GET) response = await axios.get(url);
        else if (verb === HttpVerbs.POST && data)
            response = await axios.post(url, data);
        else if (verb === HttpVerbs.PUT && data)
            response = await axios.put(url, data);
        else if (verb === HttpVerbs.DELETE) response = await axios.delete(url);
    } catch (err) {
        if (err.response) response.data = err.response;
        if (err.response && err.response.status)
            response.status = err.response.status;
    }
    return { data: response.data, status: response.status };
};

const get = async (endpoint) => {
    return await req(endpoint, HttpVerbs.GET);
};

const post = async (endpoint, data) => {
    return await req(endpoint, HttpVerbs.POST, data);
};

const put = async (endpoint, data) => {
    return await req(endpoint, HttpVerbs.PUT, data);
};

const _delete = async (endpoint) => {
    return await req(endpoint, HttpVerbs.DELETE);
};

export default {
    get,
    post,
    put,
    _delete,
};
