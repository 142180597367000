import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Button as MuiButton } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useCityState } from "../context/CityContext";

const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: "none",
        padding: "0.5rem 3rem",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 20,
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            margin: 0,
        },
        borderRadius: 10,
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    secondary: {
        backgroundColor: "#111",
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    cta: {
      backgroundColor: theme.palette.cta.main,
      color: "#fff",
      "&:hover": {
          backgroundColor: theme.palette.cta.dark,
      },
    },
}));

function Button(props) {
    const classes = useStyles();
    const city = useCityState();
    return (
        <>
            {props.link ? (
                <MuiButton
                    className={clsx(
                        classes.root,
                        props.secondary ? classes.secondary :
                          (props.cta ? classes.cta : classes.primary),
                        props.className
                    )}
                    onClick={props.onClick}
                    disabled={props.disabled}
                    component={Link}
                    to={
                        city
                            ? `/windshield-repair/${city
                                  .replace(/ /g, `-`)
                                  .toLocaleLowerCase()}`
                            : `/stores`
                    }
                >
                    {props.children}
                </MuiButton>
            ) : (
                <MuiButton
                    className={clsx(
                        classes.root,
                        props.secondary ? classes.secondary : classes.primary,
                        props.className
                    )}
                    onClick={props.onClick}
                    disabled={props.disabled}
                >
                    {props.children}
                </MuiButton>
            )}
        </>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    link: PropTypes.bool,
};

export default Button;
