import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    makeStyles,
    IconButton,
    Button,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import { IMAGE_API_URI } from "../../../Constants";

const useStyles = makeStyles((theme) => ({
    logo: {
        maxHeight: 60,
        marginRight: "1rem",
        width: "auto",
    },
    drawer: {
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        overflow: "auto",
        width: 295,
        maxWidth: "100%",
    },
    drawerList: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    drawerNav: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    drawerHomeButton: {
        padding: "6px 8px",
        flexGrow: 1,
    },
    drawerMenuButton: {
        marginLeft: 0,
        color: "#fff",
    },
    listItem: {
        textTransform: "unset",
        fontWeight: "bold",
        marginRight: "1rem",
        color: "#fff",
    },
    innerListItem: {
        textTransform: "unset",
        fontWeight: "bold",
        marginRight: "1rem",
        color: "#fff",
        backgroundColor: theme.palette.primary.light,
        paddingLeft: "3rem",
    },
    mobileMenuIcon: {
        fontSize: "2rem",
    },
}));

function NavDrawer(props) {
    const classes = useStyles();
    return (
        <Drawer
            className={props.className}
            anchor={props.anchor || "right"}
            open={props.openDrawer}
            onClose={() => props.setOpenDrawer(false)}
        >
            <div className={classes.drawer}>
                <List className={classes.drawerList}>
                    <ListItem className={classes.drawerNav}>
                        <IconButton
                            edge="start"
                            aria-label="menu"
                            className={classes.drawerMenuButton}
                            onClick={() => props.setOpenDrawer(false)}
                        >
                            <MenuIcon className={classes.mobileMenuIcon} />
                        </IconButton>
                        <Button
                            onClick={() => props.setOpenDrawer(false)}
                            component={Link}
                            to="/"
                            className={classes.drawerHomeButton}
                        >
                            <ListItemIcon>
                                <img
                                    src={`${IMAGE_API_URI}${props.logo}`}
                                    alt=""
                                    className={classes.logo}
                                    width={props.logoWidth}
                                    height={props.logoHeight}
                                />
                            </ListItemIcon>
                        </Button>
                    </ListItem>
                </List>
                <Divider />
                <List className={classes.drawerList}>
                    <ListItem
                        button
                        onClick={() => props.setOpenDrawer(false)}
                        component={Link}
                        to="/stores"
                        className={classes.listItem}
                    >
                        <ListItemText primary="Our Locations" />
                    </ListItem>
                    <Divider />
                    {props.routes.map((x) => (
                        <React.Fragment key={x.label}>
                            {x.pages.length === 1 && (
                                <ListItem
                                    button
                                    onClick={() => props.setOpenDrawer(false)}
                                    component={Link}
                                    to={x.pages[0].to}
                                    key={`${x.pages[0].text} ${x.pages[0].to}`}
                                    className={classes.listItem}
                                >
                                    <ListItemText primary={x.label} />
                                </ListItem>
                            )}
                            {x.pages.length > 1 && (
                                <>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary={x.label} />
                                    </ListItem>
                                    {x.pages.map((page) => (
                                        <ListItem
                                            button
                                            onClick={() =>
                                                props.setOpenDrawer(false)
                                            }
                                            component={Link}
                                            to={page.to}
                                            key={`${page.text} ${page.to}`}
                                            className={classes.innerListItem}
                                        >
                                            <ListItemText primary={page.text} />
                                        </ListItem>
                                    ))}
                                </>
                            )}
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}

NavDrawer.propTypes = {
    className: PropTypes.string,
    anchorId: PropTypes.string,
    anchor: PropTypes.string, // top, left, bottom, right
    openDrawer: PropTypes.bool.isRequired,
    setOpenDrawer: PropTypes.func.isRequired,
    logo: PropTypes.any.isRequired,
    routes: PropTypes.array.isRequired,
};

export default NavDrawer;
