import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    makeStyles,
    Zoom,
    Fab,
    useScrollTrigger,
    Hidden,
} from "@material-ui/core";
import { KeyboardArrowUp as BackToTopIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        color: "#fff",
        zIndex: 999,
    },
}));

function ScrollToTop(props) {
    const classes = useStyles();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 400,
    });
    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            `#${props.anchorId}`
        );
        if (anchor)
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    };
    return (
        <Hidden mdUp>
            <Zoom
                in={trigger}
                className={clsx(classes.root, props.className)}
                role="presentation"
            >
                <Fab
                    color="primary"
                    size="small"
                    aria-label="scroll back to top"
                    onClick={handleClick}
                >
                    <BackToTopIcon />
                </Fab>
            </Zoom>
        </Hidden>
    );
}

ScrollToTop.propTypes = {
    className: PropTypes.string,
    anchorId: PropTypes.string.isRequired,
};

export default ScrollToTop;
