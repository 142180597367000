import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline",

        '& a': {
            color: "#00863F",
        },
    },
}));

function RichText(props) {
    const classes = useStyles();
    return (
        <Typography
            component="div"
            variant="body1"
            className={clsx(classes.root, props.className)}
        >
            <ReactMarkdown
                level={props.level}
                plugins={[gfm]}
                allowDangerousHtml={true}
            >
                {props.children}
            </ReactMarkdown>
        </Typography>
    );
}

RichText.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.string.isRequired,
};

export default RichText;
