import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        gridColumnGap: "1em",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        padding: "0.5rem 2rem",
        borderRadius: "20px 20px 0 0",
    },
    title: {
        display: "inline-block",
        fontWeight: 400,
        fontSize: 28,
        lineHeight: "38px",
    },
    cta: {
        display: "inline-block",
        color: "#fff",
        fontFamily: '"Palanquin", "Helvetica", sans-serif',
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "24px",
    },
    left: {
        borderRadius: "0 20px 0 0",
        [theme.breakpoints.down("md")]: {
            borderRadius: 0,
        },
    },
    right: {
        borderRadius: "20px 0 0 0",
        [theme.breakpoints.down("md")]: {
            borderRadius: 0,
        },
    },
    top: {
        borderRadius: 0,
    },
}));

function Header(props) {
    const classes = useStyles();
    const getHeaderClass = (loc) => {
        if (loc === `left`) {
            return classes.left;
        } else if (loc === `right`) {
            return classes.right;
        } else if (loc === `top`) {
            return classes.top;
        }
    };
    const level = props.level ? 'h' + props.level : 'h2';
    return (
        <Grid
            item
            xs={12}
            className={clsx(
                classes.root,
                props.className,
                getHeaderClass(props.location)
            )}
        >
            <Typography variant={level} className={classes.title}>
                {props.children}
            </Typography>
            {props.cta && (
                <Link key={props.cta.url} to={props.cta.url} className={classes.cta}>
                    {props.cta.text}
                </Link>
            )}
        </Grid>
    );
}

Header.propTypes = {
    className: PropTypes.string,
    children: PropTypes.string.isRequired,
};

export default Header;
