import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import Navigation from "../components/main/Navigation";
import Footer from "../components/main/Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
    },
    skipLink: {
        margin: 0,
        "& li": {
            height: 0,
            width: 0,
            listStyle: "none",
        },
        "& li:focus": {
            color: "#333",
            outline: "none",
        },
    },
    screenReaderShortcut: {
        position: "absolute !important",
        clip: "rect(0, 0, 0, 0)",
        height: 1,
        width: 1,
        border: 0,
        overflow: "hidden",
        "&:focus": {
            clip: "auto !important",
            height: "auto",
            width: "auto",
            display: "block",
            fontSize: "1em",
            fontWeight: 700,
            padding: "15px 23px 14px",
            color: "#333",
            background: "#f7f3ed",
            zIndex: 100000,
            textDecoration: "none",
            boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.6)",
        },
    },
}));

function MainLayout(props) {
    const classes = useStyles();
    return (
        <>
            <ul
                id="SkipNavigation"
                title="Skip Navigation"
                className={classes.skipLink}
            >
                <li>
                    <a
                        href="#main-content"
                        className={classes.screenReaderShortcut}
                    >
                        skip to content
                    </a>
                </li>
            </ul>
            <Navigation
                logo={props.navLogo}
                logoWidth={props.navLogoWidth}
                logoHeight={props.navLogoHeight}
                routes={props.navRoutes}
                transparent={props.navTransparent}
            />
            <main id="main-content" className={classes.root}>
                {props.children}
            </main>
            <Footer
                title={props.footerTitle}
                trademark={props.footerTrademark}
                image={props.footerImage}
                imageWidth={props.footerImageWidth}
                imageHeight={props.footerImageHeight}
                primaryNav={props.footerPrimaryNav}
                secondaryNav={props.footerSecondaryNav}
                locations={props.footerLocations}
            />
        </>
    );
}

MainLayout.propTypes = {
    navLogo: PropTypes.any.isRequired,
    navLogoWidth: PropTypes.number.isRequired,
    navLogoHeight: PropTypes.number.isRequired,
    navRoutes: PropTypes.array.isRequired, // { label: string, pages: [{ to: string, text:string }] }
    navTransparent: PropTypes.bool,
    footerTitle: PropTypes.string.isRequired,
    footerTrademark: PropTypes.string.isRequired,
    footerImage: PropTypes.any.isRequired,
    footerImageWidth: PropTypes.number.isRequired,
    footerImageHeight: PropTypes.number.isRequired,
    footerPrimaryNav: PropTypes.array.isRequired, // { to, text }
    footerSecondaryNav: PropTypes.array.isRequired, // { to, text }
    footerLocations: PropTypes.array.isRequired, // { province, city, address }
};

export default MainLayout;
