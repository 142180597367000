import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
    makeStyles,
    Grid,
    IconButton,
    Hidden,
    Collapse,
    Button,
} from "@material-ui/core";
import { ExpandMore as ArrowIcon } from "@material-ui/icons";

import SimpleLocationPanel from "./SimpleLocationPanel";

const useStyles = makeStyles((theme) => ({
    mt: {
        marginTop: 5,
    },
    title: {
        color: "#00863F",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 24,
        lineHeight: "26px",
        textTransform: "unset",
    },
    collapse: {
        width: "100%",
    },
    expand: {
        color: "#00863F",
        fontSize: 40,
        transition: "0.25s ease-in-out",
    },
    expanded: {
        transform: "rotate(180deg)",
    },
    iconButton: {
        marginTop: "0.5rem",
    },
}));

function SimpleLocations(props) {
    const classes = useStyles();
    const [expand, setExpand] = useState(false);
    return (
        <Grid container className={clsx(classes.mt, props.className)}>
            <Grid container justify="center" alignItems="center">
                <Hidden smUp>
                    <Button
                        className={classes.title}
                        component={Link}
                        to="/stores"
                    >
                        {props.title}
                    </Button>
                </Hidden>
                <Hidden xsDown>
                    <Button
                        className={classes.title}
                        onClick={() => setExpand(!expand)}
                    >
                        {props.title}
                    </Button>
                </Hidden>
                <Hidden xsDown>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => setExpand(!expand)}
                        aria-label="expand footer to show all locations"
                    >
                        <ArrowIcon
                            className={clsx(
                                classes.expand,
                                expand ? classes.expanded : ``
                            )}
                        />
                    </IconButton>
                </Hidden>
            </Grid>
            <Hidden xsDown>
                <Collapse in={expand} className={classes.collapse}>
                    <Grid container justify="center" className={classes.mt}>
                        {props.locations.map((x) => (
                            <SimpleLocationPanel
                                key={x.province}
                                province={x.province}
                                cities={x.cities}
                            />
                        ))}
                    </Grid>
                </Collapse>
            </Hidden>
        </Grid>
    );
}

SimpleLocations.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    locations: PropTypes.array.isRequired,
};

export default SimpleLocations;
