import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: 0,
    },
}));

function Meta(props) {
    const classes = useStyles();
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="keywords" content={props.keywords} />
            <meta name="description" content={props.description} />
            <body className={classes.root} />
        </Helmet>
    );
}

Meta.propTypes = {
    title: PropTypes.string.isRequired,
    keywords: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default Meta;
