import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import RichText from "../../main/RichText";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "4rem 0",
        padding: "0 10rem",
        [theme.breakpoints.down("md")]: {
            padding: "0 8rem",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0 6rem",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0 2rem",
        },
    },
    title: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 20,
        lineHeight: "24px",
        marginBottom: 10,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1rem",
        },
    },
    subtitleContainer: {
        marginBottom: "1rem",
    },
    subtitle: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 40,
        lineHeight: "50px",
        [theme.breakpoints.down("xs")]: {
            fontSize: 28,
            lineHeight: "34px",
        },
    },
    paragraph: {
        fontSize: 20,
        lineHeight: "26px",
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
            lineHeight: "20px",
        },
    },
    left: {
        paddingRight: "2rem",
        [theme.breakpoints.down("md")]: {
            paddingRight: 0,
            paddingBottom: "1rem",
        },
    },
    right: {
        paddingLeft: "2rem",
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingTop: "1rem",
        },
    },
    center: {
        textAlign: "center",
    },
}));

function Details(props) {
    const classes = useStyles();
    return (
        <Grid
            container
            className={clsx(
                classes.root,
                props.data.center ? classes.center : ``
            )}
        >
            {props.data.title && (
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {props.data.title}
                    </Typography>
                </Grid>
            )}
            {props.data.subtitle && (
                <Grid item xs={12} className={classes.subtitleContainer}>
                    <Typography className={classes.subtitle}>
                        {props.data.subtitle}
                    </Typography>
                </Grid>
            )}
            {props.data.paragraph_1 && props.data.paragraph_2 && (
                <>
                    <Grid item xs={12} lg={6}>
                        <RichText
                            variant="body1"
                            className={clsx(classes.paragraph, classes.left)}
                        >
                            {props.data.paragraph_1}
                        </RichText>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <RichText
                            variant="body1"
                            className={clsx(classes.paragraph, classes.right)}
                        >
                            {props.data.paragraph_2}
                        </RichText>
                    </Grid>
                </>
            )}
            {props.data.paragraph_1 && !props.data.paragraph_2 && (
                <Grid item xs={12}>
                    <RichText variant="body1" className={classes.paragraph}>
                        {props.data.paragraph_1}
                    </RichText>
                </Grid>
            )}
        </Grid>
    );
}

Details.propTypes = {
    data: PropTypes.any,
};

export default Details;
