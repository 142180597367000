import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#00863F",
            light: "#00a04b",
            dark: "#006630",
            contrastText: "#fff",
        },
        secondary: {
            main: "#76ff03",
            light: "#91ff35",
            dark: "#52b202",
            contrastText: "#111",
        },
        cta: {
            main: '#ed1c2e',
            dark: '#D01121',
            contrastText: "#fff",
        }
    },
    typography: {
        fontFamily: `"Palanquin", "Helvetica", sans-serif`,
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
});

export default theme;
