import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

import { IMAGE_API_URI } from "../../../Constants";

const useStyles = makeStyles((theme) => ({
    img: {
        width: "100%",
        maxWidth: "unset",
        height: "100%",
        maxHeight: 700,
        objectFit: "cover",
        [theme.breakpoints.down("lg")]: {
            maxHeight: "unset",
        },
    },
    imgCentered: {
        clipPath: "circle(68% at 60% 50%)",
    },
    imgSlightlyUp: {
        clipPath: "ellipse(58% 103% at 60% 36%)",
    },
    imgUp: {
        clipPath: "ellipse(62% 102% at 64% 19%)",
    },
    imgSlightlyDown: {
        clipPath: "ellipse(56% 95% at 60% 63%)",
    },
    imgDown: {
        clipPath: "ellipse(61% 100% at 62% 76%)",
    },
    wrapper: {
        flex: "1 1 0",
        display: "flex",
        overflow: "hidden",
        position: "relative",
        "&::before": {
            position: "absolute",
            content: "''",
            display: "block",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1,
            background:
                "linear-gradient(165.78deg, rgba(17, 17, 17, 0.9) 4%, rgba(17, 17, 17, 0.75) 12%, rgba(17, 17, 17, 0) 67%)",
            [theme.breakpoints.down("md")]: {
                content: "none",
            },
        },
        [theme.breakpoints.down("md")]: {
            flex: "none",
            maxWidth: "unset",
            width: "100%",
            marginTop: "1rem",
            clipPath: "circle(89% at 70% 130%) !important",
        },
    },
    wrapperLarge: {
        [theme.breakpoints.down("md")]: {
            clipPath: "circle(90% at 70% 110%) !important",
        },
    },
}));

function Hero(props) {
    const classes = useStyles();
    const getImageStyle = (ellipsesStyle) => {
        if (ellipsesStyle === `translate_slightly_up`) {
            return classes.imgSlightlyUp;
        } else if (ellipsesStyle === `translate_slightly_down`) {
            return classes.imgSlightlyDown;
        } else if (ellipsesStyle === `translate_up`) {
            return classes.imgUp;
        } else if (ellipsesStyle === `translate_down`) {
            return classes.imgDown;
        } else {
            return classes.imgCentered;
        }
    };
    return (
        <div
            className={clsx(
                classes.wrapper,
                getImageStyle(props.ellipsesStyle),
                props.height > 600 ? classes.wrapperLarge : ``
            )}
        >
            <img
                className={clsx(classes.img, props.className)}
                src={
                    props.reactImage
                        ? props.src
                        : `${IMAGE_API_URI}${props.src}`
                }
                alt={props.alt || ``}
                width={props.width}
                height={props.height}
            />
        </div>
    );
}

Hero.propTypes = {
    className: PropTypes.string,
    data: PropTypes.any,
    src: PropTypes.any.isRequired,
    alt: PropTypes.string,
    height: PropTypes.number.isRequired,
    ellipsesStyle: PropTypes.string,
    reactImage: PropTypes.bool,
};

export default Hero;
