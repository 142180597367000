import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Grid } from "@material-ui/core";

import { IMAGE_API_URI } from "../../../Constants";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    image: {
        width: "100%",
        height: "auto",
        [theme.breakpoints.down("md")]: {
            maxHeight: 300,
            objectFit: "cover",
        },
    },
    imageLeft: {
        borderRadius: "20px 0 0 20px",
        [theme.breakpoints.down("md")]: {
            borderRadius: "20px 20px 0 0",
        },
    },
    imageRight: {
        borderRadius: "0 20px 20px 0",
        [theme.breakpoints.down("md")]: {
            borderRadius: "20px 20px 0 0",
        },
    },
    imageTop: {
        borderRadius: "20px 20px 0 0",
        [theme.breakpoints.down("md")]: {
            borderRadius: "20px 20px 0 0",
        },
    },
    imageBottom: {
        borderRadius: "0 0 20px 20px",
        [theme.breakpoints.down("md")]: {
            borderRadius: "20px 20px 0 0",
        },
    },
}));

function Image(props) {
    const classes = useStyles();
    const getImageClass = (imageLocation) => {
        if (imageLocation === `left`) {
            return classes.imageLeft;
        } else if (imageLocation === `right`) {
            return classes.imageRight;
        } else if (imageLocation === `top`) {
            return classes.imageTop;
        } else if (imageLocation === `bottom`) {
            return classes.imageBottom;
        }
    };
    return (
        <Grid
            item
            xs={12}
            lg={
                props.location === `left` || props.location === `right` ? 6 : 12
            }
            className={classes.root}
        >
            <img
                className={clsx(classes.image, getImageClass(props.location))}
                src={`${IMAGE_API_URI}${props.src}`}
                alt={props.alt || ""}
                width={props.width}
                height={props.height}
            />
        </Grid>
    );
}

Image.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    location: PropTypes.string.isRequired,
};

export default Image;
