import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Typography } from "@material-ui/core";

import Hero from "./Hero";

import RichText from "../../main/RichText";
import Button from "../../../tags/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    content: {
        // flexGrow: 1,
        flex: "1 1 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        paddingLeft: "8rem",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "4rem",
            padding: 0,
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 25,
            alignItems: "flex-start",
        },
    },
    textContainer: {
        textAlign: "left",
        marginTop: 28,
    },
    title: {
        color: "#fff",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontWeight: 400,
        fontSize: 78,
        lineHeight: "64px",
        [theme.breakpoints.down("xs")]: {
            fontSize: 36,
            lineHeight: "30px",
        },
        "& p": {
            marginBottom: "0.5rem",
        },
    },
    text: {
        color: "#fff",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 28,
        lineHeight: "28px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 20,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "1rem",
        },
    },
    button: {
        marginBottom: "2rem",
        [theme.breakpoints.down("xs")]: {
            margin: "0 1.5rem 0 0",
        },
    },
    subtitle: {
        margin: "28px 0",
    },
}));

function Header(props) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, props.className)}>
            <div className={classes.content}>
                <div className={classes.textContainer}>
                    <RichText className={classes.title}>{props.title}</RichText>
                    {props.subtitle.includes(`<br/>`) ? (
                        <RichText
                            className={clsx(classes.text, classes.subtitle)}
                        >
                            {props.subtitle}
                        </RichText>
                    ) : (
                        <Typography
                            variant="h1"
                            className={clsx(classes.text, classes.subtitle)}
                        >
                            {props.subtitle}
                        </Typography>
                    )}
                    {props.btnText && (
                        <Button
                            secondary={!props.btnCta}
                            cta={props.btnCta}
                            link
                            className={classes.button}>
                            {props.btnText}
                        </Button>
                    )}
                    {props.children}
                </div>
            </div>
            <Hero
                src={props.imgUrl}
                alt={props.imgAlt}
                width={props.imgWidth}
                height={props.imgHeight}
                ellipsesStyle={props.imgEllipsesStyle}
                reactImage={props.reactImage}
            />
        </div>
    );
}

Header.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    btnText: PropTypes.string,
    btnCta: PropTypes.bool,
    imgUrl: PropTypes.any.isRequired,
    imgAlt: PropTypes.string,
    imgHeight: PropTypes.number.isRequired,
    imgEllipsesStyle: PropTypes.string,
    children: PropTypes.any,
    reactImage: PropTypes.bool,
};

export default Header;
