import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid } from "@material-ui/core";

import Card from "./Card";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
    },
}));

function Cards(props) {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            {props.data.content.map((card, index) => {
                return (
                    <Card
                        key={`${card.title}_${index}`}
                        imgSrc={card.image.url}
                        imgAlt={card.image.alternativeText}
                        imgWidth={card.image.width}
                        imgHeight={card.image.height}
                        title={card.title}
                        content={card.content}
                        columns={12 / props.data.content.length}
                        full={card.full_width}
                    />
                );
            })}
        </Grid>
    );
}

Cards.propTypes = {
    data: PropTypes.any,
    full: PropTypes.bool,
};

export default Cards;
