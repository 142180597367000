import React, { createContext, useContext, useState } from "react";

const CityStateContext = createContext();
const CityDispatchContext = createContext();

export const CityProvider = ({ children }) => {
    const [city, setCity] = useState(null);
    return (
        <CityDispatchContext.Provider value={setCity}>
            <CityStateContext.Provider value={city}>
                {children}
            </CityStateContext.Provider>
        </CityDispatchContext.Provider>
    );
};

export const useCityState = () => useContext(CityStateContext);
export const useCityDispatch = () => useContext(CityDispatchContext);
