import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Typography, Button, Grid } from "@material-ui/core";

import Location from "./Location";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "4rem 4rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    btnContainer: {
        padding: "3rem 2rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    btn: {
        borderRadius: 10,
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 20,
        lineHeight: "24px",
        padding: "1rem 2rem",
        width: 250,
        maxWidth: "100%",
        textTransform: "unset",
        backgroundColor: "#111",
        color: "#fff",
        margin: "0 1rem",
        "&:hover": {
            color: "#111",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0.5rem 0",
            width: "100%",
        },
    },
    btnSelected: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            color: theme.palette.primary.main,
        },
    },
    title: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 28,
        lineHeight: "38px",
    },
}));

function LocationButton(props) {
    const classes = useStyles();
    return (
        <Button
            key={props.province}
            id={props.province}
            onClick={() => props.setSelectedBtn(props.province)}
            className={clsx(
                classes.btn,
                props.province === props.selectedBtn
                    ? classes.btnSelected
                    : classes.btn
            )}
        >
            {props.province.replace(/_/g, ` `)}
        </Button>
    );
}

function AllLocations(props) {
    const classes = useStyles();
    const provinces = Array.from(new Set(props.data.map((x) => x.province)));
    const [selectedBtn, setSelectedBtn] = useState(`All`);

    const [locations, setLocations] = useState(props.data);

    useEffect(() => {
        setLocations(props.data);
    }, [props.data]);

    const onButtonSelect = (btn) => {
        setSelectedBtn(btn);
        setLocations(
            btn.toLocaleLowerCase() === `all`
                ? props.data
                : props.data.filter((x) => x.province === btn)
        );
    };

    return (
        <div className={clsx(classes.root, props.className)}>
            <Typography variant="h1" className={classes.title}>
                All Apple Auto Glass® Locations
            </Typography>
            <div className={classes.btnContainer}>
                <LocationButton
                    province="All"
                    selectedBtn={selectedBtn}
                    setSelectedBtn={onButtonSelect}
                />
                {provinces.map((x) => (
                    <LocationButton
                        key={x}
                        province={x}
                        selectedBtn={selectedBtn}
                        setSelectedBtn={onButtonSelect}
                    />
                ))}
            </div>
            <Grid container spacing={3}>
                {locations
                    .filter((x) => x.closed !== true)
                    .map((x, index) => (
                        <Grid item xs={12} md={6} lg={4} xl={3} key={x.city}>
                            <Location
                                key={x.city}
                                city={x.city}
                                addressLine1={x.address_line_1}
                                addressLine2={x.address_line_2}
                                phone={x.phone_number}
                                hidephone={x.hide_phone_number}
                                shortinfo={x.location_short_info}
                                shortinfo2={x.location_short_info_2}
                                enablingCustomMessage={x.enable_custom_message}
                                custommessage={x.custom_message}
                                showingsimpleinfo={x.showing_simple_info}
                                url={`/windshield-repair/${x.city
                                    .replace(/ /g, `-`)
                                    .toLocaleLowerCase()}`}
                                lat={x.latitude}
                                lng={x.longitude}
                                distance={x.distance}
                                index={index}
                            />
                        </Grid>
                    ))}
            </Grid>
        </div>
    );
}

AllLocations.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
};

export default AllLocations;
