import React, { useState } from "react";
import PropTypes from "prop-types";

import NavBar from "./NavBar";
import NavDrawer from "./NavDrawer";
import ScrollToTop from "./ScrollToTop";

function Navigation(props) {
    const [openDrawer, setOpenDrawer] = useState(false);
    const anchorId = `Navigation`;
    const navDrawerLocation = "right";
    return (
        <>
            <NavBar
                logo={props.logo}
                logoWidth={props.logoWidth}
                logoHeight={props.logoHeight}
                routes={props.routes}
                anchorId={anchorId}
                setOpenDrawer={setOpenDrawer}
                transparent={props.transparent}
            />
            <NavDrawer
                logo={props.logo}
                logoWidth={props.logoWidth}
                logoHeight={props.logoHeight}
                routes={props.routes}
                anchorId={anchorId}
                anchor={navDrawerLocation}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
            />
            <ScrollToTop anchorId={anchorId} />
        </>
    );
}

Navigation.propTypes = {
    logo: PropTypes.any.isRequired,
    routes: PropTypes.array.isRequired,
    transparent: PropTypes.bool,
};

export default Navigation;
