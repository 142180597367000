import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
    makeStyles,
    IconButton,
    Button,
    Hidden,
    CircularProgress,
} from "@material-ui/core";
import {
    LocationOff as LocationOffIcon,
    LocationOn as LocationOnIcon,
} from "@material-ui/icons";

import CalendarIcon from "../../../assets/icons/calendar.svg";
import CalculatorIcon from "../../../assets/icons/calculator.svg";

import Modal from "../Modal";

import { useCityState, useCityDispatch } from "../../../context/CityContext";
import LocationService from "../../../services/LocationService";
import RestService from "../../../services/RestService";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
        },
    },
    link: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        color: "#fff",
        fontSize: 15,
        textTransform: "uppercase",
        [theme.breakpoints.down("lg")]: {
            fontSize: 11,
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 10,
        },
    },
    findLoc: {
        fontSize: 14,
        lineHeight: "18px",
    },
    button: {
        borderRadius: "10px",
        backgroundColor: "#EE1B2E",
        color: "white",
        marginLeft: "1rem",
    },
    progress: {
        color: "#fff",
        marginLeft: "1.5rem",
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
    },
    modalButtons: {
        display: "flex",
        flexDirection: "row",
    },
    city: {
        justifyContent: "flex-end",
        fontSize: 20,
        lineHeight: "24px",
    },
}));

function LocationContainer(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.hideOnSmallScreens ? (
                <Hidden smDown>{props.children}</Hidden>
            ) : (
                <>{props.children}</>
            )}
        </div>
    );
}

function LocationNav(props) {
    const classes = useStyles();
    const city = useCityState();
    const setCity = useCityDispatch();
    const [loading, setLoading] = useState(false);
    const [modalType, setModalType] = useState(`appointment`);
    const [modalOpen, setModalOpen] = useState(false);
    const [locationDenied, setLocationDenied] = useState(true);

    useEffect(() => {
        navigator &&
            navigator.permissions &&
            navigator.permissions.query &&
            navigator.permissions
                .query({ name: `geolocation` })
                .then((res) => {
                    if (res.state !== `denied`) {
                        setLocationDenied(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    }, []);

    const onLocationClick = async () => {
        setLoading(true);
        try {
            const myCoordinates = await LocationService.getMyCoords(navigator);
            const locationsResponse = await RestService.get(`/locations`);
            const formattedLocations = locationsResponse.data.map((x) => {
                return {
                    name: x.city,
                    latitude: x.latitude,
                    longitude: x.longitude,
                };
            });
            const myLocation = LocationService.getNearestLocation(
                myCoordinates.latitude,
                myCoordinates.longitude,
                formattedLocations
            );
            if (myLocation) {
                setCity(myLocation);
            }
        } catch (err) {
            setLocationDenied(true);
        }
        setLoading(false);
    };

    const onBookAppointmentClick = () => {
        setModalType(`appointment`);
        setModalOpen(true);
    };

    const onRequestQuoteClick = () => {
        setModalType(`quote`);
        setModalOpen(true);
    };

    const onModalClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Modal type={modalType} open={modalOpen} onClose={onModalClose} />
            <LocationContainer hideOnSmallScreens={props.hideOnSmallScreens}>
                {city ? (
                    <>
                        <div className={classes.buttons}>
                            <Button
                                className={clsx(classes.link, classes.city)}
                                component={Link}
                                to={`/windshield-repair/${city.toLocaleLowerCase()}`}
                            >
                                {city}
                            </Button>
                            <div className={classes.modalButtons}>
                                <Button
                                    startIcon={
                                        <img src={CalendarIcon} alt="" />
                                    }
                                    className={classes.link}
                                    onClick={onBookAppointmentClick}
                                    aria-label="book an appointment at this location"
                                >
                                    Book Appointment
                                </Button>
                                <Button
                                    startIcon={
                                        <img src={CalculatorIcon} alt="" />
                                    }
                                    className={classes.link}
                                    onClick={onRequestQuoteClick}
                                    aria-label="request a quote from this location"
                                >
                                    Request Quote
                                </Button>
                            </div>
                        </div>
                        <IconButton
                            className={classes.button}
                            component={Link}
                            to={`/windshield-repair/${city.toLocaleLowerCase()}`}
                        >
                            <LocationOnIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        {locationDenied ? (
                            <Button
                                className={clsx(classes.link, classes.findLoc)}
                                component={Link}
                                to="/stores"
                                aria-label="view all locations"
                            >
                                Find Location
                            </Button>
                        ) : (
                            <Button
                                className={clsx(classes.link, classes.findLoc)}
                                onClick={onLocationClick}
                                disabled={loading}
                                aria-label="detect your current location to find a nearby store"
                            >
                                Find Location
                            </Button>
                        )}
                        {loading ? (
                            <CircularProgress className={classes.progress} />
                        ) : (
                            <>
                                {locationDenied ? (
                                    <IconButton
                                        className={classes.button}
                                        component={Link}
                                        to="/stores"
                                        aria-label="view all locations"
                                    >
                                        <LocationOffIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        className={classes.button}
                                        onClick={onLocationClick}
                                        disabled={loading}
                                        aria-label="detect your current location to find a nearby store"
                                    >
                                        <LocationOffIcon />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </>
                )}
            </LocationContainer>
        </>
    );
}

LocationNav.propTypes = {
    hideOnSmallScreens: PropTypes.bool,
};

export default LocationNav;
