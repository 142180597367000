import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, Grid, Typography } from "@material-ui/core";

import { IMAGE_API_URI } from "../../../Constants";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: "2rem 3rem !important",
        [theme.breakpoints.down("md")]: {
            padding: "2rem 1rem !important",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "2rem 2rem !important",
        },
    },
    imageContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    imageContainerFull: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: 270,
        [theme.breakpoints.down("lg")]: {
            height: 200,
        },
        [theme.breakpoints.down("sm")]: {
            height: 160,
        },
    },
    image: {
        maxHeight: "100%",
        height: "min-content",
    },
    imageFull: {
        borderRadius: "20px",
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    title: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 18,
        lineHeight: "38px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 16,
            lineHeight: "24px",
        },
    },
    text: {
        fontFamily: `"Palanquin", "Helvetica", sans-serif`,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
            lineHeight: "20px",
        },
    },
}));

function Card(props) {
    const classes = useStyles();
    return (
        <Grid
            item
            xs={12}
            sm={6}
            lg={props.columns <= 4 ? props.columns : 3}
            className={clsx(classes.root, props.className)}
        >
            <div
                className={
                    props.full
                        ? classes.imageContainerFull
                        : classes.imageContainer
                }
            >
                <img
                    src={`${IMAGE_API_URI}${props.imgSrc}`}
                    alt={props.imgAlt}
                    className={clsx(
                        classes.image,
                        props.full ? classes.imageFull : ``
                    )}
                    width={props.imgWidth}
                    height={props.imgHeight}
                />
            </div>
            <Typography
                variant={props.full ? `h2` : `body1`}
                className={classes.title}
            >
                {props.title}
            </Typography>
            <Typography variant="body1" className={classes.text}>
                {props.content}
            </Typography>
        </Grid>
    );
}

Card.propTypes = {
    className: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    columns: PropTypes.number,
};

export default Card;
