import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
    makeStyles,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Menu,
    MenuItem,
    Hidden,
} from "@material-ui/core";
import { Menu as MenuIcon, ExpandMore as ArrowIcon } from "@material-ui/icons";

import { IMAGE_API_URI } from "../../../Constants";
import LocationNav from "./LocationNav";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
    },
    rootTransparent: {
        flexGrow: 1,
        position: "absolute",
        backgroundColor: "#00000000",
        [theme.breakpoints.down("md")]: {
            position: "static",
            backgroundColor: theme.palette.primary.main,
        },
    },
    toolbar: {
        padding: "0.25rem 4rem",
        [theme.breakpoints.down("sm")]: {
            padding: "0.25rem 0.8rem",
        },
    },
    secondaryToolbar: {
        backgroundColor: theme.palette.primary.dark,
        padding: "1rem",
    },
    logo: {
        maxHeight: 60,
        marginRight: "1rem",
        width: "auto",
    },
    logoButton: {
        marginRight: theme.spacing(2),
    },
    link: {
        textTransform: "unset",
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontWeight: "normal",
        marginRight: "1rem",
        color: "#fff",
        fontSize: 20,
        lineHeight: "36px",
    },
    carret: {
        fontSize: "24px !important",
    },
    menu: {
        "& ul": {
            backgroundColor: theme.palette.primary.light,
            color: "#fff",
        },
    },
    menuItem: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
    },
    mobileMenuButton: {
        marginLeft: "1rem",
        color: "#fff",
    },
    mobileMenuIcon: {
        fontSize: "2rem",
    },
}));

function NavBar(props) {
    const classes = useStyles();
    const [menuSelected, setMenuSelected] = useState(``);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const openMenu = (event, menu) => {
        setMenuSelected(menu);
        setMenuAnchor(event.currentTarget);
    };
    const closeMenu = () => {
        setMenuAnchor(null);
        setMenuSelected(``);
    };
    return (
        <AppBar
            position="static"
            className={clsx(
                props.transparent ? classes.rootTransparent : classes.root,
                props.className
            )}
            elevation={props.transparent ? 0 : 2}
        >
            <Toolbar
                id={props.anchorId}
                title="Main Navigation"
                className={classes.toolbar}
            >
                <Button
                    className={classes.logoButton}
                    component={Link}
                    to="/"
                    aria-label="home"
                >
                    <img
                        src={`${IMAGE_API_URI}${props.logo}`}
                        alt=""
                        className={classes.logo}
                        width={props.logoWidth}
                        height={props.logoHeight}
                    />
                </Button>
                <Hidden mdDown>
                    <Button
                        component={Link}
                        to="/stores"
                        className={classes.link}
                    >
                        Our Locations
                    </Button>
                    {props.routes.map((x) => (
                        <React.Fragment key={x.label}>
                            {x.pages.length === 1 && (
                                <Button
                                    className={classes.link}
                                    component={Link}
                                    to={x.pages[0].to}
                                >
                                    {x.label}
                                </Button>
                            )}
                            {x.pages.length > 1 && (
                                <>
                                    <Button
                                        className={classes.link}
                                        endIcon={
                                            <ArrowIcon
                                                className={classes.carret}
                                            />
                                        }
                                        onClick={(e) =>
                                            openMenu(e, `${x.label} Menu`)
                                        }
                                    >
                                        {x.label}
                                    </Button>
                                    <Menu
                                        id={`${x.label} Menu`}
                                        anchorEl={menuAnchor}
                                        open={
                                            menuSelected === `${x.label} Menu`
                                        }
                                        onClose={closeMenu}
                                        keepMounted
                                        elevation={2}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        className={classes.menu}
                                    >
                                        {x.pages.map((y) => (
                                            <MenuItem
                                                key={y.to}
                                                component={Link}
                                                to={y.to}
                                                onClick={closeMenu}
                                                className={classes.menuItem}
                                            >
                                                {y.text}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </Hidden>
                <LocationNav hideOnSmallScreens />
                <Hidden lgUp>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => props.setOpenDrawer(true)}
                        className={classes.mobileMenuButton}
                    >
                        <MenuIcon className={classes.mobileMenuIcon} />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Hidden mdUp>
                <div
                    className={clsx(classes.toolbar, classes.secondaryToolbar)}
                >
                    <LocationNav />
                </div>
            </Hidden>
        </AppBar>
    );
}

NavBar.propTypes = {
    className: PropTypes.string,
    anchorId: PropTypes.string,
    setOpenDrawer: PropTypes.func.isRequired,
    logo: PropTypes.string.isRequired,
    routes: PropTypes.array.isRequired,
    transparent: PropTypes.bool,
};

export default NavBar;
