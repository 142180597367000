import React from "react";
import PropTypes from "prop-types";

import Meta from "./Meta";
import Lead from "./Lead";
import Content from "./Content";
import MainLayout from "../../layouts/MainLayout";

import RestService from "../../services/RestService";
import DataService from "../../services/DataService";

import useStructuredData from "../../hooks/useStructuredData";
import useHeadScript from "../../hooks/useHeadScript";
import useBodyScript from "../../hooks/useBodyScript";
import useHeadNoScript from "../../hooks/useHeadNoScript";
import useBodyNoScript from "../../hooks/useBodyNoScript";

function Page(props) {
    useStructuredData(props.structuredData);
    useHeadNoScript(props.headNoScriptContent);
    useBodyNoScript(props.bodyNoScriptContent);
    useHeadScript(props.headScriptContent);
    useBodyScript(props.bodyScriptContent);
    return (
        <>
            <Meta
                title={props.metaTitle}
                keywords={props.metaKeywords}
                description={props.metaDescription}
            />
            <MainLayout
                navLogo={props.navLogo}
                navLogoWidth={props.navLogoWidth}
                navLogoHeight={props.navLogoHeight}
                navRoutes={props.navRoutes}
                navTransparent={props.navTransparent}
                footerTitle={props.footerTitle}
                footerTrademark={props.footerTrademark}
                footerImage={props.footerImage}
                footerImageWidth={props.footerImageWidth}
                footerImageHeight={props.footerImageHeight}
                footerPrimaryNav={props.footerPrimaryNav}
                footerSecondaryNav={props.footerSecondaryNav}
                footerLocations={props.footerLocations}
            >
                <Lead data={props.lead} path={props.path} />
                <Content data={props.content} path={props.path} />
            </MainLayout>
        </>
    );
}

Page.getInitialProps = async (ctx) => {
    // use url to retrieve page from server
    let path = ``;
    if (ctx.match && ctx.match.url) path = ctx.match.url;
    if (ctx.location && ctx.location.pathname) path = ctx.location.pathname;
    // START REDIRECTS
    if (path === `/about` || path === `/about/`)
        return { redirectTo: `/six-point-care-process/` };
    if (path === `/scratch-removal` || path === `/scratch-removal/`)
        return { redirectTo: `/windshield-repair-or-replacement/` };
    // END REDIRECTS
    const pageRes = await RestService.get(`/pages?friendly_url=${path}`);
    // if not found then redirect to not found page
    if (pageRes.data.length === 0) return { redirectTo: "/not-found" };
    // run any user level page redirects
    if (pageRes.data[0].redirect_url !== null)
        return { redirectTo: pageRes.data[0].redirect_url };
    // get necessary page data
    const data = await DataService.getPrelimData();
    // inject any script content
    const jsRes = await RestService.get(`/js`);
    return {
        lead: pageRes.data[0].lead,
        content: pageRes.data[0].content,
        metaTitle: pageRes.data[0].metadata.meta_title,
        metaKeywords: pageRes.data[0].metadata.meta_keywords,
        metaDescription: pageRes.data[0].metadata.meta_description,
        navTransparent: pageRes.data[0].lead.length > 0,
        ...data,
        path,
        structuredData: pageRes.data[0].metadata.structured_data,
        headScriptContent: jsRes.data.head,
        bodyScriptContent: jsRes.data.body,
        headNoScriptContent: jsRes.data.head_noscript,
        bodyNoScriptContent: jsRes.data.body_noscript,
    };
};

Page.propTypes = {
    children: PropTypes.any,
};

export default Page;
