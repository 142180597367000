import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, Typography, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import PinIcon from "../../assets/icons/pin.svg";

import Meta from "../Page/Meta";
import MainLayout from "../../layouts/MainLayout";

import GoogleMap from "./GoogleMap";
import AllLocations from "./AllLocations";

import { DEFAULT_ZOOM, DEFAULT_CENTER, CITY_ZOOM } from "../../Constants";

import RestService from "../../services/RestService";
import DataService from "../../services/DataService";
import LocationService from "../../services/LocationService";

import useStructuredData from "../../hooks/useStructuredData";
import useHeadScript from "../../hooks/useHeadScript";
import useBodyScript from "../../hooks/useBodyScript";
import useHeadNoScript from "../../hooks/useHeadNoScript";
import useBodyNoScript from "../../hooks/useBodyNoScript";

const useStyles = makeStyles((theme) => ({
    root: {},
    map: {
        backgroundColor: theme.palette.primary.main,
        padding: "2rem 8rem",
        [theme.breakpoints.down("md")]: {
            padding: "2rem 4rem",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1rem 2rem",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "1rem 1rem",
        },
    },
    mapInfo: {
        minHeight: 100,
        backgroundColor: "#111",
        color: "#fff",
        marginTop: "-8rem",
        padding: "9rem 1rem 2rem 1rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    mapInfoContainer: {
        marginBottom: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 1rem",
    },
    findLocCont: {
        marginBottom: "1.2rem",
    },
    icon: {
        marginRight: "1rem",
    },
    city: {
        color: "#fff",
        borderColor: "#fff",
        "& *": {
            color: "#fff",
            borderColor: "#fff",
        },
        minWidth: 200,
    },
    findLoc: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        fontSize: 40,
        lineHeight: "50px",
    },
}));

function Locations(props) {
    useStructuredData(props.structuredData);
    useHeadNoScript(props.headNoScriptContent);
    useBodyNoScript(props.bodyNoScriptContent);
    useHeadScript(props.headScriptContent);
    useBodyScript(props.bodyScriptContent);
    const classes = useStyles();

    const [locations, setLocations] = useState(props.locations);
    const [markers, setMarkers] = useState(
        props.locations
            .filter((x) => x.closed !== true)
            .map((x, i) => {
                return {
                    lat: x.latitude,
                    lng: x.longitude,
                    city: x.city,
                    index: i,
                };
            })
    );

    const [selectedCity, setSelectedCity] = useState(null);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [center, setCenter] = useState(DEFAULT_CENTER);

    const getZoom = () => {
        const width = window.outerWidth || 599;
        if (width < 600) {
            return DEFAULT_ZOOM;
        } else if (width < 900) {
            return 2;
        } else if (width < 1200) {
            return 3;
        } else {
            return 4;
        }
    };

    useEffect(() => {
        LocationService.getMyCoords(navigator)
            .then((loc) => {
                if (loc.latitude && loc.longitude) {
                    const newLocationData = props.locations
                        .map((x) => {
                            const distance = LocationService.getDistanceInKm(
                                loc.latitude,
                                loc.longitude,
                                x.latitude,
                                x.longitude,
                                true
                            );
                            return {
                                distance,
                                ...x,
                            };
                        })
                        .sort((a, b) => {
                            if (a.distance < b.distance) return -1;
                            if (a.distance > b.distance) return 1;
                            return 0;
                        });
                    setLocations(newLocationData);
                    setMarkers(
                        newLocationData.map((x, i) => {
                            return {
                                lat: x.latitude,
                                lng: x.longitude,
                                city: x.city,
                                index: i,
                            };
                        })
                    );
                    setZoom(CITY_ZOOM);
                    setCenter([
                        newLocationData[0].latitude,
                        newLocationData[0].longitude,
                    ]);
                }
            })
            .catch((err) => {
                const zoomLevel = getZoom();
                setZoom(zoomLevel);
            });
    }, []);

    const zoomOnLocation = (city) => {
        if (locations.findIndex((x) => x.city === city) !== -1) {
            const loc = locations.find((x) => x.city === city);
            setZoom(CITY_ZOOM);
            setCenter([parseFloat(loc.latitude), parseFloat(loc.longitude)]);
        } else {
            const zoomLevel = getZoom();
            setZoom(zoomLevel);
            setCenter(DEFAULT_CENTER);
        }
    };

    return (
        <>
            <Meta
                title={props.metaTitle}
                keywords={props.metaKeywords}
                description={props.metaDescription}
            />
            <MainLayout
                navLogo={props.navLogo}
                navLogoWidth={props.navLogoWidth}
                navLogoHeight={props.navLogoHeight}
                navRoutes={props.navRoutes}
                navTransparent={props.navTransparent}
                footerTitle={props.footerTitle}
                footerTrademark={props.footerTrademark}
                footerImage={props.footerImage}
                footerImageWidth={props.footerImageWidth}
                footerImageHeight={props.footerImageHeight}
                footerPrimaryNav={props.footerPrimaryNav}
                footerSecondaryNav={props.footerSecondaryNav}
                footerLocations={props.footerLocations}
            >
                <div className={classes.root}>
                    <div className={classes.map}>
                        <GoogleMap
                            large
                            zoom={zoom}
                            center={center}
                            onChange={(e) => setZoom(e.zoom)}
                            markers={markers}
                            setZoom={setZoom}
                        />
                    </div>
                    <div className={classes.mapInfo}>
                        <div
                            className={clsx(
                                classes.mapInfoContainer,
                                classes.findLocCont
                            )}
                        >
                            <img
                                src={PinIcon}
                                alt=""
                                className={classes.icon}
                                width={22}
                                height={28}
                            />
                            <Typography
                                component="label"
                                className={classes.findLoc}
                            >
                                Find a Location
                            </Typography>
                        </div>
                        <div className={classes.mapInfoContainer}>
                            <Autocomplete
                                id="CityInput"
                                options={props.cities}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Enter a City"
                                        className={classes.city}
                                    />
                                )}
                                value={selectedCity}
                                onChange={(event, newValue) => {
                                    setSelectedCity(newValue);
                                    zoomOnLocation(newValue);
                                }}
                            />
                        </div>
                    </div>
                    <AllLocations data={locations} />
                </div>
            </MainLayout>
        </>
    );
}

Locations.getInitialProps = async (ctx) => {
    const locationsRes = await RestService.get(`/locations`);
    const data = await DataService.getPrelimData();
    const jsRes = await RestService.get(`/js`);
    return {
        metaTitle: `Find A Location | Auto Windshield Repair - Apple Auto Glass`,
        metaKeywords: `apple,auto,glass,locations`,
        metaDescription: `Visit one of our 20 locations across Canada! Book an appointment, request a free quote & get directions.`,
        navTransparent: false,
        ...data,
        locations: locationsRes.data.sort((a, b) => {
            if (a.city < b.city) return -1;
            if (a.city > b.city) return 1;
            return 0;
        }),
        cities: locationsRes.data.map((x) => x.city),
        structuredData: `
        {
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "Apple Auto Glass - Locations",
            "url": "https://www.appleautoglass.com/stores/",
            "potentialAction": {
                "@type": "SearchAction",
                "target": "{search_term_string}",
                "query-input": "required name=search_term_string"
            }
        }`,
        headScriptContent: jsRes.data.head,
        bodyScriptContent: jsRes.data.body,
        headNoScriptContent: jsRes.data.head_noscript,
        bodyNoScriptContent: jsRes.data.body_noscript,
    };
};

export default Locations;
