import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, Typography, Button } from "@material-ui/core";

import Meta from "./Page/Meta";
import RestService from "../services/RestService";
import { IMAGE_API_URI } from "../Constants";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#daffec",
    },
    text: {
        marginTop: "2rem",
    },
    button: {
        fontFamily: `"Palanquin Dark", "Helvetica", sans-serif`,
        textTransform: "unset",
        color: "#fff",
        marginTop: "2rem",
    },
    title: {
        textAlign: "center",
        fontSize: "4rem",
        [theme.breakpoints.down("md")]: {
            fontSize: "2rem",
        },
    },
}));

function NotFound(props) {
    const classes = useStyles();
    return (
        <>
            <Meta
                title="Not Found | Apple Auto Glass"
                keywords="apple,auto,glass,not,found"
                description="Not found page for Apple Auto Glass website."
            />
            <div className={classes.root}>
                <img
                    src={`${IMAGE_API_URI}${props.logo}`}
                    alt=""
                    width={props.logoWidth}
                    height={props.logoHeight}
                />
                <Typography
                    variant="h1"
                    className={clsx(classes.text, classes.title)}
                >
                    404 - Page Not Found
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    The given url is invalid.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/"
                    className={classes.button}
                >
                    Return to home page.
                </Button>
            </div>
        </>
    );
}

NotFound.getInitialProps = async (ctx) => {
    const logoRes = await RestService.get(`/logo`);
    return {
        logo: logoRes.data.secondary.url,
        logoWidth: logoRes.data.secondary.width,
        logoHeight: logoRes.data.secondary.height,
    };
};

export default NotFound;
