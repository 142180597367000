import React from "react";
import PropTypes from "prop-types";

import Header from "../../components/generic/Header";

function Lead(props) {
    return (
        <>
            {props.data.map((component, index) => {
                return (
                    component.__component &&
                    component.__component === `generic.header` && (
                        <Header
                            key={`${component.__component}_${index}`}
                            title={component.title}
                            subtitle={component.subtitle}
                            btnText={component.button.text}
                            btnCta={component.button.cta}
                            imgUrl={component.image.url}
                            imgAlt={component.image.alternativeText}
                            imgHeight={component.image.height}
                            imgWidth={component.image.width}
                            imgEllipsesStyle={component.ellipses_style}
                            reactImage={false}
                        />
                    )
                );
            })}
        </>
    );
}

Lead.propTypes = {
    data: PropTypes.array,
    path: PropTypes.string,
};

export default Lead;
